.bold {
    font-weight: bold;
}

.group-title {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.dados-relatorio {
    background-color: transparent !important;
    border-radius: 5px;
    padding: 1px;
    border: none;
    margin-top: 20px
}

@media only screen and (min-width: 450px) and (max-width: 700px) {
    .dados-relatorio {
        padding: 15px !important;
    }
}

@media only screen and (max-width: 449px) {
    .dados-relatorio {
        padding: 5px !important;
    }
}

.group-filter {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.filter {
    padding-bottom: 5px;
}

.group-filter h6 {
    font-size: 14px;
}

.group-data {
    margin-bottom: 3rem;
}

.dados-relatorio .group-data {
    overflow: auto;
}

.group-data .coluna {
    font-size: .7rem;
    background-color: #020029;
    color: #fff;
    padding: .3rem;
}

.group-filter .filter {
    font-size: .8rem
}

table {
    width: 100%;
}

.td-data {
    padding: .6rem .6rem 0 .6rem !important;
    font-size: .8rem
}

.tr-data .td-data:last-child {
    padding-bottom: .5rem;
}

.agrupamento {
    padding: 1rem .5rem .5rem .5rem;
}

.last {
    padding: 1rem;
}

.auditoria {
    font-size: .7rem;
}

.td-total {
    border-top: 1px dashed #ccc;
}

.total-geral {
    font-weight: bold;
    padding-top: 1.5rem;
}

.td-total-geral {
    border-top: 2px solid #ccc;
}

.table-filter {
    width: auto !important;
}

.td-filter {
    font-size: .8rem;
    padding-bottom: 5px;
}

.group-title .ordenavel {
    position: relative;
    cursor: pointer;
    padding-right: 1.3rem !important;
}

.group-title td+.group-title td {
    border: 1px solid white
}

.group-title .ordenavel::before {
    font-family: 'icomoon';
    content: "\edaf";
    position: absolute;
    bottom: 0.5em;
    display: block;
    opacity: 0.4;
    right: 1.1em;
    font-weight: 800;
}

.group-title td.ordenamento_asc::after {
    opacity: 1 !important;
}

.group-title .ordenavel::after {
    font-family: 'icomoon';
    content: "\e82c";
    position: absolute;
    bottom: 0.3em;
    display: block;
    opacity: 0.4;
    right: 0.4em;
    font-weight: 800;
}

.group-title .ordenavel.ordenamento_desc::before {
    opacity: 1 !important;
}

.group-title .editavel::after {
    font-family: 'icomoon';
    content: "\e82c";
    position: absolute;
    bottom: 0.3em;
    display: block;
    opacity: 0.4;
    right: 0.4em;
    font-weight: 800;
}


@media print {

    .group-title .ordenavel::before,
    .group-title .ordenavel::after {
        display: none;
    }

    .group-title .coluna+.coluna {
        border-left: unset !important;
        border-left: unset !important;
    }
}

.group-title .coluna+.coluna {
    border-left: 0.5px solid rgb(255, 255, 255);
    border-left: 0.5px solid rgba(255, 255, 255, 0.356);
}