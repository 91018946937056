.dados-visualizacao {
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 30px;
}

.data-title {

    font-weight: 700;
    margin-bottom: 5px;
    line-height: 1.2;
    display: block;
    font-size: 1rem;
}

.group-title {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.dados-visualizacao+.dados-visualizacao {
    margin-top: 40px;
}

.titulo-visualizacao{
    text-align:center;
}
@media print {
    .dados-visualizacao {
        background-color: transparent !important;
        border-radius: 5px;
        padding: 30px;
        border: none;
    }

    body {
        margin: -10px -10px 10px -10px;
    }

    .card-title>div {
        display: grid;
        align-content: center;
    }

    .card-title h5 {
        font-weight: bold;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .coluna-1 {
        width: 413, 33px !important;
    }

    .coluna-2 {
        width: ‭826.66px !important;
    }

    .coluna-3 {
        width: 1.239, 99‬ !important;
    }

    .coluna-4 {
        width: 1653.32px !important;
    }

    .coluna-5 {
        width: 2065px !important;
    }

    .coluna-6 {
        width: 2479.98px !important;
    }

    .dados-visualizacao+.dados-visualizacao {
        margin-top: 0px !important;
    }
}

.table-visualizacao thead tr td {
    font-weight: bold;
    font-size: 1rem;
    border-top: unset;
    border-bottom: 3px solid #fff;
}

.table-visualizacao tbody tr td {
    font-size: 14px !important;
    border-bottom: 3px solid #fff;
}

.table-visualizacao td {
    background: unset;
}

.coluna-1 {
    flex: 0 0 8.333%;
    width: 8.333%;
}

.coluna-2 {
    flex: 0 0 16.666%;
    width: 16.666%;
}

.coluna-3 {
    flex: 0 0 25%;
    width: 25%
}

.coluna-4 {
    flex: 0 0 33.333333%;
    width: 33.333333%;
}

.coluna-5 {
    flex: 0 0 41.666667%;
    width: 41.666667%;
}

.coluna-6 {
    flex: 0 0 50%;
    width: 50%;
}
/* 
.data-grid th,
.data-grid td {
    padding: .75rem;
} */

.visualizacao {
    background-color: #f6f6f6;
    margin-top: -10px;
 }

 .texto-superior-descricao{
    font-size: 13px;
    font-weight: '500';
    color: #555
 }

 .assinatura {
    display: flex;
    justify-content: center;
    padding: 150px;

}

.linha-assinatura {

    display: flex;
    justify-content: center;
    
    width: 300px;
    border-top: 1px solid #000;
}

.linha-assinatura-recibo {
    display: flex;
    justify-content: center;
    border-top: 1px solid #000;
    width: 80%;
}

.dados-visualizacao-conferencia {
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 15px;
}

.dados-visualizacao-conferencia+.dados-visualizacao-conferencia {
    margin-top: 10px;
}