.img-sem-conexao {
    width: 100% !important;
}
.row-sem-conexao{
    min-height: 600px;
    overflow: hidden !important;
}
.titulo{
    padding-top: 80px;
    font-size: 50px;
    text-align: center;
}
.modal-bloqueado .ReactModal__Content {
    overflow: hidden !important;
}
.texto{
    font-size: 20px;
    text-align: center;
}

@media only screen and (max-width: 570px) {
  
}

@media only screen and (max-width: 375px) {
   
    .row-sem-conexao{
        min-height: 0px !important;
        margin-top: 0px;
    }
}