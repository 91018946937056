.height {
    height: 100vh;
}

.card {
    flex-direction: 'column';
    flex: 1;
}

.card-img {
    padding-top: 25px;
    background: var(--cor-primaria);
    ;
    height: 50%;
    color: white;
    min-height: 350px;
    max-height: 450px;
}

.card-form {
    background: #fff;
    height: 50%;
    min-height: 350px;
    padding: 30px !important;
    border-top-right-radius: 50px;
}

.background-img {
    background: url('../../../../assets/img/doctor.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.overlay {
    background-color: rgba(2, 0, 41, 0.5) !important;
}

.content-login {
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyright {
    position: absolute;
    bottom: 0;
    height: 40px;
    margin-top: 40px;
    color: white;
}

.link-login {
    color: #fff
}

.link-login:hover,
.forgot-password:hover {
    color: #030044;
    text-decoration: underline;
}

.forgot-password {
    color: #016FE6 !important;
}

@media only screen and (max-width: 1199px) {
    .img-logo {
        width: 250px;
        display: table;
        margin: 0 auto;
        padding-top: 30px;
    }

    .border-radius-bottom-left-50 {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 50px !important;
    }

    .border-radius-top-right-50 {
        border-bottom-left-radius: 50px !important;
        border-top-right-radius: 0px !important;
    }
}

@media only screen and (max-width: 570px) {
    .border-radius-bottom-left-50 {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .border-radius-top-right-50 {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .content-logo {
        min-width: unset !important;
        min-height: 304px !important;
    }

    .form-login {
        min-width: unset !important;
        min-height: 349px !important;
    }
}

@media only screen and (max-width: 500px) {
    .card-form {
        height: auto;
    }
}

@media only screen and (min-width: 1200px) {
    .img-logo {
        width: 100%;
        padding-top: 25px;
    }
}

.mt-8 {
    margin-top: 8px
}

.form-login {
    min-width: 475px;
    min-height: 351px;
}

.content-logo {
    min-width: 285px;
    min-height: 351px;
}