.span-lembrete{
    margin-top: 10px !important;
    width:100% !important;
    
}
    

/*
.container-lembrete {
    border-radius: 5px;
    background-color: #fff !important;
    min-height: 25px !important;
    width: 95% !important;
    color: #5d5d5d !important;
    align-items: center !important;
    vertical-align: middle !important;
    margin: 0px 0px 10px 15px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-right: 500px !important;
    font-weight: bold;
    border: 1px solid black;
}*/

.container-botao-direita {
    justify-content: center;
    display: flex;
    min-width: 100px;
    min-height: 100%;
}


.detalhes-lembrete{
    padding-top: 15px !important;
    padding-bottom: -10px !important;
}

.nome-usuario{
    text-align: right;
    justify-content:right;
    font-weight: bold;
}

.botao-cadastro-lembrete{
    float: right;
}