.label-404 {
    color: white !important;
    font-size: 10em;
}

.path-404 {
    color: white !important;
    font-size: 3em;
}


@media only screen and (max-width: 750px) {
    .label-404 {
        font-size: 5em;
    }

    .path-404 {
        font-size: 1em;
    }
}

.container-404{
    background-color: var(--cor-primaria);;
}