.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 1500ms ease-out;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.ReactModal__Content {
    -webkit-transform: translate(100%);
    transform: translate(100%);
}

.ReactModal__Content--after-open {
    -webkit-transform: translate(0px);
    transform: translate(0px);
    transition: all 300ms ease-in;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content--before-close {
    -webkit-transform: translate(0);
    transform: translate(0);
    transition: all 300ms ease-in;
}

.ReactModal__Content.modal-dialog {
    border: none;
    background-color: transparent;
}

.ReactModal__Overlay {
    z-index: 99999;
}

.ReactModal__Body--open .tooltip-input-modal.show.tooltip{
    z-index: 99999999999999 !important;
}

.ReactModalPortal{
    min-height: 450px !important    ;
}

.modal-content{
    min-height: 200px !important;
    height: auto !important;
}


@media only screen and (max-width: 500px) {
    .modal-content{
        left: unset !important;
        width: calc(100% - 40px) !important;
        margin: 0 20px;
    }
}

@keyframes mymove {
  from {height: 0px;}
  to {height: 100%;}
}

.cadastro-header-open {
    /* transform: translate(0px); */
    /* transition: all 3000ms ease-in-out; */
    position: relative;
    animation: mymove 2s forwards;
}


/*  */

/* .slide-up-bottom-div {
    position: fixed;
    right: 20px;
    bottom: 17px !important;
    -webkit-animation: slideUp 0.5s forwards;
    -webkit-animation-delay: 0.1s;
    animation: slideUp 0.5s forwards;
    animation-delay: 0.1s;
    cursor: pointer;
    z-index: 11;
} */