:root {
    --cor-primaria: rgb(2, 0, 41);
    --cor-primaria-rgba01: rgba(2, 0, 41, 0.1);
    --cor-primaria-rgba08: rgba(2, 0, 41, 0.8);
}

.vsc-initialized {
    margin: 0px !important;
}

@font-face {
    font-family: 'Shopping';
    src: url('../fonts/shopping-webfont.woff2') format('woff2'), url('../fonts/shopping-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('../fonts/comfortaa-variablefont_wght-webfont.woff2') format('woff2'), url('../fonts/comfortaa-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#root {
    height: 100%;
}

body {
    font: 14px "Comfortaa" !important;
    /*margin: 20px;*/
    background-color: rgb(248, 248, 248) !important;
}

.btn {
    padding: 0.75rem 2rem 0.6rem 2rem !important;
    border-radius: 50px !important;
    margin: 0px 5px;
}

.primary {
    background: var(--cor-primaria) !important;
    border-color: var(--cor-primaria) !important;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
}

.secondary {
    background-color: #fff !important;
    color: var(--cor-primaria);
}

.default {
    background-color: #f8f8f8 !important;
    border-color: var(--cor-primaria);
}

.direita-fixa-em-baixo {
    position: absolute;
    bottom: 10px;
    right: 30px;
}

.esquerda-fixa-em-baixo {
    position: absolute;
    bottom: 10px;
    left: 30px;
}

.color-primary {
    color: var(--cor-primaria);
}

.background-white {
    background-color: #fff !important;
    ;
}

.pt-37 {
    padding-top: 37px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.padding-100 {
    padding: 100px 0 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.Toastify__toast--success {
    background: #fdfdfd !important;
    color: #1e6b14 !important;
}

.Toastify__toast--success .Toastify__progress-bar {
    background-color: #1e6b14 !important;
}

.Toastify__toast--error {
    background: #fdfdfd !important;
    color: #6b1414 !important;
}

.Toastify__toast--warning {
    background: #fdfdfd !important;
    color: #db8318 !important;
}

.Toastify__toast--warning .Toastify__progress-bar {
    background-color: #db8318 !important;
}

.Toastify__toast--error .Toastify__progress-bar {
    background-color: #6b1414 !important;
}

.Toastify__toast {
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    z-index: 999999999;
}

.Toastify__toast-body {
    white-space: pre-line !important;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right.toast-container {
    z-index: 999999999;
}

.border-radius-bottom-left-50 {
    border-bottom-left-radius: 50px !important;
}

.border-radius-top-right-50 {
    border-top-right-radius: 50px !important;
}

.error {
    color: rgb(131, 12, 12);
}

.icone {
    margin-bottom: 3px;
}

.button-right {
    position: absolute;
    bottom: -15px;
    right: 30px;
}

@media only screen and (max-width: 500px) {
    .direita-fixa-em-baixo {
        position: relative;
        padding-right: 0px;
        float: right;
        padding-top: 30px;
        right: 0;
    }

    .border-radius-bottom-left-50 {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .border-radius-top-right-50 {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .btn {
        padding: 10px 15px !important;
        font-size: 12px;
    }
}

.btn-filter {
    min-width: 20px !important;
    padding: 10px 15px !important;
    font-size: 15px;
}

@media only screen and (max-height: 815px) {
    .copyright {
        display: none;
    }
}

.content-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-bottom-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.content-bottom-left {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.container-layout {
    background-color: #F8F8F8;
    height: calc(100% - 120px);
}

.menu {
    background-color: #fff;
    height: 100%;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    width: 120px;
    z-index: 999999;
    position: fixed;
    display: unset !important;
}

.sub-menu {
    display: block !important;
}

.menu li a svg {
    text-align: -webkit-match-parent;
    font-size: 35px;
    padding-bottom: 10px;
}

.menu li a {
    height: 110px !important;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    border-left: 5px #fff solid;
    border-bottom: 1px solid #eee;
    color: var(--cor-primaria);
    ;
    -webkit-transition: color 300ms;
    transition: color 300ms;
    -webkit-transition: background 300ms;
    transition: background 300ms;
}

.height-100-por-cento {
    height: 100% !important;
}

.p-0 {
    padding: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-5px {
    padding-left: 5px !important;
}

.m-0 {
    margin: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 1px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.menu-active {
    border-left: 5px var(--cor-primaria) solid !important;
}

.container-menu {
    position: fixed;
    height: 100%;
}

.error-tooltip .tooltip-inner {
    border: 1px rgb(131, 12, 12) solid;
    background: #fff;
}

.error-tooltip .arrow::before {
    border-bottom-color: rgb(131, 12, 12) !important;
}

.navbar {
    position: fixed !important;
    margin-left: unset !important;
    width: 100%;
    background-color: red;
    height: 70px;
    background-color: #fff;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    z-index: 1030;
}

.sidebar {
    position: absolute;
    width: 100px;
    height: calc(100% - 70px);
    -webkit-transition-property: margin;
    transition-property: margin;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0;
    transition-delay: 0;
    z-index: 11;
    margin-top: 70px;
}

.logo-menu {
    height: 50px;
}

.logo-menu-equipe {
    height: 100px;
}

.logo-dashboard {
    width: 40% !important;
    padding-top: 10%;
}

.div-center {
    text-align: center !important;
}

.content {
    padding: 40px !important;
}

.toast-container {
    padding-top: 70px !important;
}


@media only screen and (max-width: 500px) {
    .container-body {
        margin-left: 0px;
    }
}

.ml-menu {
    margin-left: 120px;
}

.remove-margin {
    margin-left: -120px
}

.remove-margin-submenu {
    margin-left: -350px
}

.icone-menu {
    font-size: 25px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
    .logo-menu {
        height: 35px !important;
    }

    .email-logado {
        border-radius: 50%;
        border: .5px solid var(--cor-primaria);
        font-size: 15px !important;
    }

    .usuario-logado-clicavel {
        padding-top: 16px !important;
    }

    .usuario-logado {
        margin: 2px 25px 0 10px !important;
    }
}

@media only screen and (max-width: 500px) {
    .logo-menu {
        height: 35px !important;
    }

    .menu {
        width: 100px;
    }

    .menu li a {
        height: 100px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        font-size: 10px !important;
    }

    .icone-menu {
        font-size: 22px;
    }

    .sidebar>ul>li>a>span:nth-child(2) {
        font-size: 10px;
    }

    .submenu {
        left: 100px !important;
        font-size: 12px;
        width: calc(100vw - 100px) !important;
    }

    .remove-margin-submenu {
        margin-left: -500px;
    }

    .content {
        padding: 20px 10px !important;
    }
}

.botao-menu-mobile {
    width: 90px;
    padding-left: 22px !important;
    z-index: 99999;
}

.botao-menu-desk {
    width: 120px;
    padding-left: 32px !important;
    z-index: 99999;
    cursor: pointer;
}

.icone-topbar {
    height: 12px;
}

.logo-centro {
    position: fixed;
    width: 100%;
    text-align: center;
}

.card {
    background: white;
    border-radius: calc(0.15rem - 1px);
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    border-width: 0px !important;
    z-index: 9;
    -webkit-transition: width 4s;
    transition: width 5s;
}

.row .card-pequeno:nth-child(1),
.row .card-pequeno:nth-child(2) {
    padding-top: 0 !important;
}

.card-pequeno {
    cursor: pointer;
    padding: 15px 0
}

.card-pequeno.active {
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.card-pequeno .card {
    padding: 20px;
    min-height: 70px;
}

.card-title {
    font-size: 1.1rem !important;
    margin-bottom: 20px !important;
}

.card-body {
    padding: 1.75rem;
    flex: 1 1 auto;
}

.form-control {
    padding: 0.75rem !important;
    height: unset !important;
    font-size: 0.8rem !important;
    border-radius: 0.1rem !important;
}

.direita {
    float: right;
}

.sub-item-menu .icone-menu {
    margin-right: 10px !important;
    font-size: 20px !important;
    color: #000000;
}

.sub-item-menu {
    padding-left: 30px;
}

.sub-item-menu .nav-link {
    padding: 8px 0;
    padding-top: 8px;
    padding-right: 0px;
    padding-bottom: 8px;
    padding-left: 0px;
    color: #000000;
}

.submenu {
    position: fixed;
    left: 120px;
    background-color: white;
    height: 100%;
    border-left: 1px solid #f3f3f3;
    width: 230px;
    padding-top: 0px;
    box-shadow: 20px 3px 30px rgba(150, 150, 150, 0.1), 0 0 0 rgba(100, 100, 100, 0.1);
    -webkit-transition-property: margin;
    transition-property: margin;
    -webkit-transition-duration: .8s;
    transition-duration: .5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0;
    transition-delay: 0;
    z-index: 10 !important;
}

.sub-active:before {
    content: " ";
    background: var(--cor-primaria);
    ;
    border-radius: 10px;
    position: absolute;
    width: 4px;
    height: 4px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 10px;
    left: 16px;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
}

::placeholder {
    /* Recent browsers */
    text-transform: none;
}

.remove-margin-menu .menu {
    box-shadow: none !important;
}

.img-teste {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.pt-7 {
    padding-top: 70px;
}

.text-right {
    text-align: right !important;
}

.custom-scrollbar {
    height: calc(100% - 70px);
    overflow: scroll;
}

.custom-scrollbar.sub-menu {
    /* height: 100%; */
    overflow: scroll;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
}

.scrollbar-primario::-webkit-scrollbar-thumb {
    background: var(--cor-primaria) !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

.mt-20 {
    margin-top: 20px
}

.mt-30 {
    margin-top: 30px
}

.mt-100 {
    margin-top: 100px
}

.mtb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.ptb-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ptb-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.plr-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.ptb-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.tooltip {
    z-index: 9 !important;
}

div.submenu>ul>li>a>span.sub-item-titulo {
    line-height: 20px !important;
}

div.submenu>ul .sub-item-menu:hover {
    background-color: #00000014;
    text-decoration: unset !important;
    cursor: pointer !important;
}

div.submenu>ul>li.list-submenu:hover {
    background-color: white;
}

div.submenu>ul>li a:hover>span.icone-menu,
div.submenu>ul>li a:hover>.sub-item-titulo {
    color: #0056b3 !important;
}

div.submenu>ul>li {
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-transition: all 300ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 300ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 300ms ease-in;
    -moz-transform: scale(1);
    transition: all 300ms ease-in;
    transform: scale(1);
}

.mb-20 {
    margin-bottom: 20px;
}

.tooltip {
    /* z-index: 9 !important; */
}

.vertical-center {
    display: grid !important;
    align-content: center !important;
}

.row-space-between {
    display: flex !important;
    justify-content: space-between;
}



.d-block-print {
    display: none;
}

.opacity-1-print {
    opacity: 0;
}

.usuario-logado {
    margin: 0px 25px 0 10px !important;
    color: var(--cor-primaria) !important;
    cursor: pointer !important;
    z-index: 12;
}

.usuario-logado-clicavel {
    height: 50px !important;
    min-height: 50px !important;
    z-index: 12;
    padding-top: 15px;
    padding-left: 20px;
}

.usuario-logado-clicavel:hover .nome-logado,
.usuario-logado-clicavel:hover .email-logado {
    color: #0056b3 !important;
}

.email-logado {
    font-size: 25px;
    margin-left: 10px;
    padding: 10px;
    color: var(--cor-primaria);
    z-index: 12;
}

.btn-group {
    z-index: 9999 !important;
}

.dropdown-dropdown-divider {
    z-index: 99999 !important;
}

.dropdown-menu {
    z-index: 9999 !important;
}

.dropdown-item {
    z-index: 9999 !important;
}

.breadcrumb {
    background-color: #f8f8f8 !important;
}

.separator {
    border: .5px solid #ccc
}

li.breadcrumb-item a span a,
li.breadcrumb-item a span {
    color: var(--cor-primaria)
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "|" !important;
}

.link-active {
    color: #6c757d !important
}

.row-start {
    display: flex;
    justify-content: flex-start;
}

.row-end {
    display: flex;
    justify-content: end;
}

.row-center {
    display: flex;
    justify-content: center;
}

.pesquisa-consulta {
    background: none;
    outline: medium none invert !important;
    outline: initial !important;
    border: 1px solid #8f8f8f;
    border-radius: 15px;
    padding: .25rem .75rem;
    font-size: .76rem;
    line-height: 1.3;
    color: #212121;
}

.pesquisa-consulta::after {
    font-family: simple-line-icons;
    content: "\e090";
    font-size: 14px;
    border-radius: 10px;
    color: #8f8f8f;
    position: absolute;
    width: 22px;
    right: 4px;
    text-align: center;
    cursor: pointer;
    top: 2px;
}

.nav-breadcrumb {
    font-size: 12px;
    line-height: 2.1;
}

.btn-xs {
    padding: .25rem .75rem;
    border-radius: 50px;
}

.card-pequeno .card:hover {
    box-shadow: 0 3px 30px var(--cor-primaria-rgba01), 0 3px 20px var(--cor-primaria-rgba01);
}

.card-pequeno .ativo {
    border-left: 3px solid var(--cor-primaria) !important;
}

.label-switch {
    margin: 0 !important
}

.CircularProgressbar.CircularProgressbar {
    width: 50px;
}

.oculto {
    display: none !important;
}

.dropdown {
    display: inline-block;
}

.dropdown__content {
    display: none;
    left: calc(100% - 220px);
}

.dropdown--active .dropdown__content {
    display: block;
    position: absolute;
}

.card-usuario-logado {
    background-color: white;
    min-height: 100px;
    width: 213px !important;
    box-shadow: 3px 19px 14px 0px var(--cor-primaria-rgba01);
    z-index: 0
}

.icone-card-usuario {
    font-size: 18px;
    color: var(--cor-primaria);
    padding-left: 20px;
}

.item-titulo-card-usuario {
    padding-left: 20px;
    font-size: 14px;
    color: var(--cor-primaria);
}

.card-usuario-nav-link {
    margin-top: 10px;
    padding-top: 10px;
    margin-left: 15px;
    text-decoration: unset !important;
    width: calc(100% - 30px) !important;
    height: 40px;
    -webkit-transition: color 300ms;
    transition: color 300ms;
    -webkit-transition: background 300ms;
    transition: background 300ms;
}

.card-usuario-nav-link:hover .item-titulo-card-usuario {
    color: #0056b3 !important;
}

.card-usuario-nav-link:hover .icone-card-usuario {
    color: #0056b3 !important;
}

.card-usuario-nav-link:hover {
    background-color: #00000014 !important;
    text-decoration: unset !important;
    cursor: pointer !important;
}

@media only screen and (max-width: 700px) {
    .dropdown__content {
        left: calc(100% - 200px);
    }

    .usuario-logado-clicavel:hover {
        box-shadow: none;
    }
}

@media only screen and (max-width: 950px) {
    .nome-logado {
        font-size: 10px;
        word-break: break-all;
        width: 10px;
    }

    .email-logado {
        margin-left: 0px;
    }
}

.nome-logado {
    vertical-align: super;
}

.switch-md {
    transform: scale(0.6);
}

.p-20 {
    padding: 20px;
}

.modal-header {
    border-bottom: unset !important;
}

.bt-2 {
    border-bottom: 2px solid var(--cor-primaria) !important;
}

.bb-1 {
    border-bottom: 1px solid #ddd;
}

.bt-1 {
    border-top: 1px solid #ddd;
}

.titulo-acao {
    font-size: 15px;
    align-items: center;
    display: flex;
}

.btn-secundario {
    border: 1px solid var(--cor-primaria) !important;
    border-radius: 50px;
    height: 49px;
}

.btn-sm {
    padding: 10px 20px !important;
}

.m-1 {
    margin: 10px !important
}

.height-7 {
    height: 70px !important
}

.ReactModalPortal .container-botoes {
    position: relative;
    bottom: 0;
    margin: 15px 0px;
}

.full .form-group {
    max-width: 100% !important;
    flex: none !important;
}

@media only screen and (max-height: 430px) {
    .ReactModalPortal .container-botoes {
        position: relative;
        bottom: 0;
    }
}

@media only screen and (max-width: 500px) {
    .ReactModal__Content {
        width: 100% !important
    }

    .h2-sm {
        font-size: 2rem !important;
    }
}

.card+.card {
    margin-top: 40px;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.modulos-permissao .cl-switch {
    padding-top: 14px !important;
    padding-right: 14px !important;
}

.modulos-permissao h4 {
    padding-top: 14px !important;
}

.pt-0 {
    padding-top: 0 !important;
}


.modulos-permissao h4 {
    padding-top: 14px !important;
}

.react-select,
.react-select__control {
    min-height: 45px !important;
    max-height: 200px !important;
    height: auto !important;
    border-radius: 0px !important;
}

.react-select__value-container,
.react-select__multi-value::-webkit-scrollbar {
    height: 20px;
    width: 30px;
}

.react-select__value-container {
    overflow: auto;
}


.react-select__menu {
    margin-top: 0px !important;
    border-radius: 0px !important;
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    /* z-index: 9999999999 !important; */
}

.react-select__menu-list::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
    background: #ddd;
}

.react-select__menu-list::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

.react-select__control--is-focused {
    border-radius: 0px !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25) !important;
}

.btn-group-padrao {
    border: unset;
    background-color: var(--cor-primaria);
    color: white;
    min-width: 40px;
    font-size: 20px;

}

.btn-group-padrao:hover {
    opacity: 0.7;
}

.btn-group-navigator {
    border: unset;
    background-color: white;
    color: var(--cor-primaria);
    min-width: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

.btn-group-navigator:hover {
    opacity: 0.7;
}

.mini-card-colorido {
    background: #020029;
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.lista-cards,
.lista-modal {
    padding-left: 0px;
}

.lista-cards li {
    list-style-type: none;
    color: white;
    background: var(--cor-primaria);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.DateInput_input {
    font-size: 0.8rem !important;
}

.SingleDatePickerInput_clearDate__default:hover {
    background: var(--cor-primaria) !important;
    background-color: var(--cor-primaria-rgba01);
}

.SingleDatePickerInput_clearDate__default {
    -webkit-transition: all 300ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 300ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 300ms ease-in;
    -moz-transform: scale(1);
    transition: all 300ms ease-in;
    transform: scale(1);
}

.SingleDatePicker,
.SingleDatePickerInput,
.DateInput {
    width: 100% !important;
}

.DayPickerKeyboardShortcuts_showSpan,
.DayPickerKeyboardShortcuts_buttonReset {
    display: none !important;
}


.lista-number {
    background-color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    color: var(--cor-primaria);
    align-items: center;
    padding-top: 2px;
    margin-right: 5px;
    font-weight: 700;
}

.mt-40 {
    margin-top: 40px !important
}

.ReactModalPortal:empty {
    display: none;
}

body:has(ul.lista-number) {
    margin-top: 100px;
}

.react-select__dropdown-indicator {
    background-color: var(--cor-primaria);
    cursor: pointer;
    padding: 12.5px 8px !important;
    margin-top: -1px !important;
}

.react-select__indicator-separator {
    display: none
}

.react-select__indicator {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}

.lista-cards .remove-li {
    background-color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    color: var(--cor-primaria);
    align-items: center;
    margin-right: 5px;
    font-weight: 700;
    position: absolute;
    right: 25px;
    cursor: pointer;
    border: none;
}

.lista-cards .remove-li:hover {
    background-color: #ddd;
}

.flex-direita {
    display: flex;
    justify-content: flex-end;
}

.btn-sm {
    padding: 10px !important;
    font-size: 15px;
}

.CalendarDay__selected {
    background-color: var(--cor-primaria) !important;
}

.CalendarDay__default:hover {
    background: var(--cor-primaria-rgba08) !important;
    color: white !important;
    border: 1px solid #e4e7e7;
    color: inherit;
}

.DateInput_input__focused {
    border-bottom: 2px solid var(--cor-primaria) !important;
}

.CalendarMonth_caption {
    color: var(--cor-primaria) !important;
}

form,
.page-login,
.overlay,
.card,
.btn-download-arquivo {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
}

form.no-animation {
    animation: unset !important;
    animation-iteration-count: unset !important;
    animation-fill-mode: unset !important;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/**calendario**/

.calendario-oculto {
    display: none !important;
}

.calendario-ativo {
    position: absolute !important;
}

.Cal__Container__root {
    font: 12px "Comfortaa" !important;
    background-color: rgb(248, 248, 248) !important;
    margin-left: -25px !important;
    margin-top: -76px !important;
}

.Cal__MonthList__root {
    overflow: scroll !important;
}

.Cal__MonthList__root::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

.Cal__MonthList__root::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
}

.Cal__MonthList__root::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

.Cal__Header__root,
.Cal__Weekdays__root,
.Cal__Header__date,
.Cal__Day__selection {
    background-color: var(--cor-primaria) !important;
}

.Cal__Weekdays__root {
    background-color: var(--cor-primaria-rgba08) !important;
}

.Cal__Month__rows {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, var(--cor-primaria-rgba01) 200%) !important;
}

.modal-header-calendar .close {
    z-index: 99999999999999 !important;
    color: white !important;
}

.form-control-autocomplete {
    flex: 1 1;
}

.display-flex {
    display: flex;
}

.lh-2 {
    line-height: 2.6;
}

.lh-1-5 {
    line-height: 1.5;
}

.margin-recibo {
    margin: 3px 3px 2px 2px
}

.table .data-grid .table-responsive-md {

    width: 600px;
}

.btn-primary {
    min-width: 138px;
    min-height: 48px;
}

.btn-primary-sm {
    color: #fff !important;
    padding: 5px 10px !important;
}

.display-none {
    display: none;
}

.fs-40 {
    font-size: 40px;
}

.fs-13 {
    font-size: 13px;
}

.p-0 {
    padding: 0 !important;
}

.p-5rem {
    padding: .5rem !important
}

.height-auto {
    height: auto !important;
}

.fw {
    font-weight: 700 !important;
}

.p-a-r {
    position: absolute;
    right: 0;
}

.m-r-15 {
    margin-right: 15px
}

.tabela-endereco th,
.tabela-endereco td {
    padding: 1.2rem .75rem !important;
    text-align: left;
    vertical-align: middle;
    background: white;
    border-bottom: 1px solid rgba(224, 224, 244, 1);
}

.tabela-endereco thead th {
    border-top: unset
}

.tabela-endereco tbody:last-child td {
    border-bottom: unset !important;
}

.space-between-100 {
    width: 100%;
    justify-content: space-between;
}

.react-select__menu {
    /* z-index: 9991000 !important; */
}

.padding-40 {
    padding: 30px !important;
}

.react-select__value-container {
    overflow: auto !important;
    min-height: 30px !important;
    height: auto !important;
    max-height: 190px !important;

}

.react-select__value-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

.react-select__value-container::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
}

.react-select__value-container::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

.card-right .modal-title {
    margin-bottom: 20px;
}

ul.lista-selecao li {
    display: block;
    padding-left: 10px;
    margin-bottom: 10px;
    color: var(--cor-primaria) !important;
}

ul.lista-selecao {
    padding-left: 0px;
}

.btn-primary-modal {
    min-width: 108px !important;
    font-size: 15px !important;
    min-height: unset !important;
    color: white !important;
}

.btn-sm.simples {
    border: 1px solid var(--cor-primaria) !important;
    background: white !important;
    color: var(--cor-primaria) !important;
}

/* Calendar */

.calendario {
    background-color: white;
    width: 100%;
    margin-bottom: 15px;
}

.semana-titulo {
    height: 30px;
    border: 1px solid var(--cor-primaria);
    display: flex;
    background-color: var(--cor-primaria);
}

.semana-titulo .dia {
    text-align: center;
    color: white;
}

@media only screen and (max-width: 360px) {
    .semana-titulo .dia {
        font-size: 12px;
    }
}

.semana .dia {
    text-align: right;
}

.semana {
    height: 12vh;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    position: relative;
}

.semana+.semana {
    border-bottom: 1px solid #ccc;
}

.dia {
    flex: 1;
    padding: 8px 5px;
    color: #444;
    position: relative;
    /* border-left:  1px solid red; */
}

.dia+.dia {
    border-left: 1px solid #ccc;
}

.semana .dia:first-child {
    border-left: 1px solid #ccc;
}

.semana-eventos {
    position: absolute;
    top: 30px;
    width: 100%;
    height: calc(120px - 30px);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
    vertical-align: baseline;
    grid-gap: 1px;
}

.cor-padrao,
.cor-padrao a {
    background-color: #00a5d9;
}

.acontecimento {
    padding: 0 .25rem;
    position: relative;
    font-size: 80%;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    margin: 1px 4px;
    border-radius: 3px;
    color: white !important;
}

.acontecimento-titulo {

    position: absolute;
    display: grid !important;
    align-content: center !important;
    width: 95%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.outro-mes {
    background-color: #efefef;
}

.dia.hoje span {
    background: #020029;
    color: white;
    padding: 6px;
    right: -4px;
    position: relative;
    border-radius: 17px;
}

.preto,
.preto a {
    background-color: #000;
    color: white !important;
}

.lista-modal li {
    border: solid 1px #ddd;
    border-radius: 10px;
    display: block;
    padding: 10px;
    cursor: pointer
}

.sem-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

}

.lista-modal li:hover {
    background-color: #eee;
}

.ml-1-between+.ml-1-between {
    margin-left: 10px;
}

.list-submenu>.rotate-arrow-icon>.icon-Arrow-Down2 {
    font-size: 15px !important;
}

.list-submenu>.rotate-arrow-icon>.sub-item-titulo,
.list-submenu>.rotate-arrow-icon>.icon-Arrow-Down2 {
    color: #999;
}

.rotate-arrow-icon.collapsed .icone-menu {
    -webkit-transform: rotate(-90deg) !important;
    transform: rotate(-90deg) !important;
}

.rotate-arrow-icon .icone-menu {
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.list-submenu span.icone-menu {
    font-size: 1.3em;
    margin-right: 10px;
    color: #8f8f8f;
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.rotate-arrow-icon.collapsed .icone-menu {
    -webkit-transform: rotate(-90deg) !important;
    transform: rotate(-90deg) !important;
}

.rotate-arrow-icon .icone-menu {
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.list-submenu span.icone-menu {
    font-size: 1.3em;
    margin-right: 10px;
    color: #8f8f8f;
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}


.bold {
    font-weight: bold;
}

@media only screen and (max-width: 325px) {
    .group-salvar-novo button {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 494px) and (min-width: 326px) {
    .group-salvar-novo {
        text-align: center;
        width: 100%;
    }
}

@media only screen and (max-width: 557px) and (min-width: 495px) {
    .group-salvar-novo button {
        width: 100%;
        margin-bottom: 10px;
    }
}

.group-salvar-novo .spinner-grow {
    /* display: unset; */
    line-height: 1.5 !important;
}

.span-hora i {
    font-size: 18px;
    font-weight: 600;
    color: #555;
}

.content-filtro .lista-selecao li a i {
    margin-right: 10px;
}

.icone+.icone {
    margin-left: 10px;
}

.sem-margin {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.__react_component_tooltip {
    background: black !important;
}

.place-bottom::after {
    border-bottom-color: black !important;
}

.th-filter .dropdown__content {
    left: unset !important;
}

.lista-filtro-grid li:hover {
    background-color: #00000014;
    text-decoration: unset !important;
    cursor: pointer !important;
}

.lista-filtro-grid {
    display: block;
    padding: 10px !important;
}

.lista-filtro-grid li {
    display: block;
    padding: 10px
}

.card-filtro-dropdown {
    background-color: #efefef;
    width: 213px !important;
    box-shadow: 3px 19px 14px 0px var(--cor-primaria-rgba01);
    z-index: 0;
    padding: 10px;
}

.card-filtro-dropdown .react-select__dropdown-indicator {
    height: 100% !important;
    display: grid !important;
    align-content: center !important;
}

.card-filtro-dropdown .react-select__clear-indicator {
    height: 100% !important;
    display: none
}

.card-filtro-dropdown .react-select__indicators {
    height: 100% !important;
    display: none
}

.card-filtro-dropdown .form-group {
    margin-bottom: 0px;
}

.card-filtro-dropdown .react-select__value-container {
    max-height: 100px !important;
    height: unset !important;

}

.card-filtro-dropdown .react-select,
.card-filtro-dropdown .react-select__control {
    max-height: 115px !important;
    height: unset !important;

}

.card-filtro-dropdown .react-select__value-container,
.card-filtro-dropdown .react-select__multi-value::-webkit-scrollbar {
    max-height: 90px !important;
    height: unset !important;
}

.data-grid th,
.data-grid td {
    padding: .4rem .5rem;
}

.data-grid .btn-acao {
    font-size: 19px !important
}

.btn-primary-filtro {
    min-width: 60px !important;
    font-size: 15px !important;
    min-height: unset !important;
    color: white !important;
}

.btn-primary-filtro.simples {
    color: var(--cor-primaria) !important;
    border-radius: unset !important;
    border: unset !important;
    background-color: unset !important;
    margin: 0px !important;
}

.btn-primary-filtro.simples:hover {
    background-color: #dfdfdf !important;
}

.btn-primary-filtro.simples+.btn-primary-filtro.simples {
    border-left: 1px solid #ddd !important
}

.btn-sm.btn-primary-filtro {
    padding: 4px !important;
    min-width: 44% !important;
}

.th-filter .icon-Filter-2 {
    margin-right: 5px;
}

.group-title .ordenavel {
    position: relative;
    cursor: pointer;
    padding-right: 1.3rem !important;
}

.th-filter .icon-Filter-2.filtrado {
    border-bottom: 2px solid;
    font-weight: 800;
}

.strikediag::before {
    background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
}

.input-imagem {
    width: 100%;
    height: 200px;
    background-color: #3f3f3f;
}

.input-imagem:hover {
    color: white;
    content: 'testandooo';
    background-color: red;
}

.input-imagem:hover::before {
    color: white;
    content: 'testandooo';
}


.dropzone-img {
    height: 150px;
    width: 150px;
    background-color: rgba(2, 0, 41, 0.1);
    position: relative;
    border-radius: 5px;
    text-align: center;
}

.img-result-crop {
    max-height: 100%;
    max-width: 100%;
}

.input-ranger {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.input-ranger:hover {
    opacity: 1;
}

.input-ranger::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--cor-primaria);
    cursor: pointer;
}

.input-ranger::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--cor-primaria);
    cursor: pointer;
}

.content-corte-imagem canvas {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.div-img-corte {
    max-width: 330px;
    max-height: 330px;
    margin-bottom: 20px;
}

.div-img-corte canvas {
    transform-origin: 0 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform-origin-x: 0;
    margin-bottom: 20px;
}

.dropzone-img:hover .middle {
    background-color: rgba(50, 50, 50, 0.5);
    opacity: 1;
    color: white;
}

.middle {
    height: 40%;
    width: 100%;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    align-items: center;
    padding: 5px;
}

.dropzone-img:hover {
    cursor: pointer;
    /* opacity: 0.3; */
}

.full-z-index {
    z-index: 9999;
}

.container-dropzone-img .btn-remove-img {
    color: white;
    padding: 2px 7px !important;
    border-radius: unset !important;
    line-height: unset;
    font-weight: 700;
    border-radius: 50% !important;
    position: absolute;
    top: 5px;
    right: 0px;
}

.container-dropzone-img .btn-remove-img:hover {
    color: #888;
}

.dropzone-img .image.inicial {
    border-radius: 5px;
    border: 1px solid var(--cor-primaria-rgba01)
}

.container-dropzone-img {
    width: 150px;
}

.container-corte-canvas .btn-alterar-img {
    width: 330px !important;
    border-radius: 0px !important;
    position: relative;
    top: -3px;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    background: #c6c6c6;
    border: unset;
}

.container-corte-canvas .btn-alterar-img:hover {
    background: #a8a8a8;
}

.width-100 {
    width: 100%
}

.titulo-header-responsivo {
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
}

.btn-responsive {
    margin: 0 !important
}

@media only screen and (min-width: 768px) {
    .text-right-md {
        text-align: right;
    }
}

.btn-simples {
    padding: 10px !important;
}

.float-bottom-right {
    position: fixed;
    z-index: 1030;
    bottom: 10px;
    right: 50% !important;
    padding-right: 40px;
}

.input-calendario-mes .Cal__Years__list {
    position: absolute;
    top: 30px;
    overflow: unset !important;
}

.group-title-consulta {
    display: flex;
    cursor: pointer;

}

.group-title-consulta .ordenavel {
    position: relative;
    cursor: pointer;
    break-inside: avoid;
    padding-right: 2.3rem !important;
}

.group-title-consulta .ordenavel::before {
    font-family: 'icomoon';
    content: "\edaf";
    position: absolute;
    bottom: -0.4em !important;
    display: block;
    opacity: 0.4;
    right: 1.1em;
    font-weight: 800;
}

.group-title-consulta .ordenamento_asc::after {
    content: "\ee32" !important;
    opacity: 1 !important;
}

.group-title-consulta .ordenavel::after {
    font-family: 'icomoon';
    content: "\e82c";
    position: absolute;
    bottom: -0.4em !important;
    display: block;
    opacity: 0.4;
    right: 0.4em;
    font-weight: 800;
}

.group-title-consulta .ordenavel.ordenamento_desc::before {
    content: "\ee31" !important;
    opacity: 1 !important;
}

.group-title-consulta .coluna+.coluna {
    border-left: 0.5px solid rgb(255, 255, 255);
    border-left: 0.5px solid rgba(255, 255, 255, 0.356);
}

.p-40 {
    padding: 40px;
}

.tag-data-grid {
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 20px;
    font-size: 11px;
    white-space: nowrap;
    background: red;
    color: white
}

.tag-data-grid-pendente {
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 20px;
    font-size: 11px;
    white-space: nowrap;
    background: #DB8319;
    color: white
}

.tag-data-grid-confirmado {
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 20px;
    font-size: 11px;
    white-space: nowrap;
    background: green;
    color: white
}


@media only screen and (max-width: 1440px) and (min-width:1220px) {

    .tag-data-grid {
        padding: 4px 10px;
        margin-left: 0 !important;
        border-radius: 20px;
        font-size: 8px;
        white-space: nowrap;
        background: red;
        color: white
    }
}

.display-block {
    display: block !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.titulo-porte-acomodacao {
    background-color: #020029;
    color: white;
    padding: 7px 10px;
    margin-bottom: 10px;
}

.react-tabs__tab--selected>.error {
    color: white !important
}

.titulo-acomodacao {
    color: var(--cor-primaria);
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 5px;
    font-weight: bold;
}

.group-acomodacao {
    margin: 0 15px;
}

.tabela-porte-acomodacao thead th {
    border-top: unset;
}

.tabela-porte-acomodacao tbody:last-child td {
    border-bottom: unset !important;
}

.tabela-porte-acomodacao th,
.tabela-porte-acomodacao td {
    padding: .5rem .75rem !important;
    text-align: left;
    vertical-align: middle;
    background: white;
    border-bottom: 1px solid rgba(224, 224, 244, 1);
    vertical-align: middle !important;
    ;
}

.margin-top-1 {
    margin-top: 1rem;
}

.margin-top-2 {
    margin-top: 2rem !important;
}

.subheader-porte-acomodacao {
    text-transform: uppercase;
    font-weight: bold;
}

.focus-button:focus {
    border: 1px solid var(--cor-primaria) !important;
    padding: 9px;
}

.icone-ordenamento {
    font-size: 11px !important;
    line-height: 13px !important;
}

.att-tabela {
    width: 100%;
    margin-bottom: 1rem;
}

.progress-bar {
    background-color: var(--cor-primaria) !important;
}

.second-toolbar {
    height: 20px;
}

.second-toolbar a {
    display: none !important;
}

.fr-box {
    width: 100%;
}

@media only screen and (min-width:1590px) {
    .teste {

        padding: 1.25rem !important;
    }
}

.none-coluna {
    display: none;
}

.ajusta-summernote {

    min-height: 300px;
    max-height: 400px;
    height: 350px;
    padding-bottom: 50px;
    padding-left: 0px !important;
    padding-right: 0px !important;

}


.item-detalhes h6 {
    font-size: 13px !important;
}

.ajusta-time-picker-modal .react-timekeeper.css-nakgy8-TimeKeeper {
    margin-top: 471px !important;
    margin-left: -18px !important;
    width: 110%;
    max-height: 407px;
    height: 100%;
}

.ajusta-time-picker-modal {
    max-height: 300px;
    height: 100%;
}

.ajusta-time-picker-modal .react-timekeeper__top-bar.css-1mn38ah-TopBar {

    width: 100% !important;
}

.ajusta-time-picker-modal .react-timekeeper__clock-wrapper.css-1lmy46j-ClockWrapper {

    width: 100% !important;
    max-height: 500px;
    height: 100%;
}

.ajusta-time-picker-modal .react-timekeeper__done-button.css-ue2et3-DoneButton {

    width: 100% !important;
}

.ajusta-time-picker-modal .react-timekeeper__done-button.css-ue2et3-DoneButton {

    display: none !important;
}

@media only screen and (max-height: 850px) {

    .ajusta-time-picker-modal .react-timekeeper.css-nakgy8-TimeKeeper {
        margin-top: 345px !important;
    }

    .ajusta-time-picker-modal .react-timekeeper__clock-wrapper.css-1lmy46j-ClockWrapper {
        max-height: 350px;
        height: 100;
    }

    .ajusta-time-picker-modal .react-timekeeper.css-nakgy8-TimeKeeper {
        width: 110%;
        max-height: 307px;
        height: 100%;
    }
}

@media only screen and (max-height: 1024px) and (min-height: 851) {

    .ajusta-time-picker-modal .react-timekeeper.css-nakgy8-TimeKeeper {
        margin-top: 551px !important;
        margin-left: -18px !important;
        width: 110%;
        max-height: 407px;
        height: 100%;
    }

}

.card-pequeno {

    z-index: 0 !important;
}

.atalhoTooltip {

    background-color: white !important;
    border-color: var(--cor-primaria) !important;
    color: var(--cor-primaria) !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    border-radius: 5px !important;
}

.iconeAtalhoTooltip {

    margin-right: 5px !important;
    font-size: 19px !important;
    margin-bottom: 3px !important;
}

.color-red {
    color: #cd1313
}

.tooltip.show {
    z-index: 0;
}

/*------------------------*/
input.hidden-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    /* clippath: inset(50%); */
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.styled-checkbox {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: var(--cor-primaria);
    border-radius: 3px;
    transition: all 150ms;
    cursor: pointer;
}

.styled-checkbox[data-checked=false] {
    background: #ccc;
}

.checkbox-container {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    cursor: pointer;
}

.icon-checkbox {
    fill: none;
    stroke: white;
    stroke-width: 2px;
}

.dashboard-auditoria,
.dashboard-agendamento,
.acesso-rapido {
    height: 270px;
}

.dashboard-small-card {
    height: 165px;
}

.log-indicator {
    width: 13px;
    height: 13px;
    border: 2px solid #145388;
    border-radius: 14px;
    display: inline-block;
}

.font-weight-medium {
    font-weight: 500;
}

.align-middle {
    vertical-align: middle;
}

.a-acesso-rapido {
    height: 100%;
    color: #020029 !important;
    text-decoration: none !important;
}

.link-acesso-rapido:hover {
    color: inherit !important;
    text-decoration: none !important;
}

.card-acesso {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-acesso:hover {
    background-color: #eee;
}

.scrollbar-container {
    margin-right: -15px;
    padding-right: 15px;
    position: relative;
    height: 100%;
    overflow-y: auto;
}

.flex-row {
    flex-direction: row;
}

.b-radius-10 {
    border-radius: 10px !important;
}

/* .card .card-title {
    margin-bottom: 1.5rem;
}
.card-title {
    font-size: 1.1rem;
} */

.jCIpyS {
    background-color: var(--cor-primaria) !important;
    color: white !important;
    width: 70px !important;
}

.cWQTGl {

    background-color: var(--cor-primaria) !important;
    color: white !important;
}

.texto-tooltip {
    font-size: 15px;
    font-weight: 400;
}

@media only screen and (max-width: 800px) {
    .ajusta-botoes {
        display: flex !important;
        justify-content: flex-end !important;
    }
}

.tooltip-plantao-es {
    top: unset !important;
    bottom: 60px;
    position: relative !important;
    left: 150px !important;
}

.fonte-branca {
    color: white !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.sequencial {

    color: red !important;
    font-weight: bold;
}

.ml-menu .sticky {
    width: calc(100% - 120px);
    /* left: 120px !important; */
}


.sticky {
    z-index: 10;
    background: #fff;
    position: fixed !important;
    top: 70px !important;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    right: 0px;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
    /* margin-left: 0px; */
    /* display: flex !important; */
}

.right-5-neg {
    right: 0px !important;
}

.fixo-agenda {
    font-size: 15px !important;
    padding: 0 15px !important;
}

.right-50 {
    right: 50px !important;
}

.teste {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.m-10 {
    margin: 5px
}


.modal-header-cadastro .headerBlank {
    min-height: 320px;
}

.procedimento-float {
    max-height: 350px;
}

.socio-float {
    max-height: 250px;
}

.bl-cinza {
    border-left: 1px solid #ccc;
}

.margin-r-279 {
    margin-right: 279px;
}

.btn-scroll-top {
    height: 32px !important;
    width: 5px !important;
}

.btn-scroll-top span.icon-Arrow-Up,
.btn-scroll-top span.icon-Arrow-Right {
    position: absolute;
    right: 13px;
    bottom: 8px;
    color: white;
}

.btn-scroll-top span.icon-Arrow-Left {
    position: absolute;
    left: 13px;
    bottom: 8px;
    color: white;
}

.float-header .tooltip {
    z-index: 10 !important;
}

.sequencial {

    vertical-align: center;
    margin-left: 5px;
    font-weight: bold;
    font-size: 12px;
    color: var(--cor-primaria);
}

.retira-borda-esquerda {

    border-left: none !important;

}

.retira-borda-direita {

    border-right: none !important;
}

.dia-semana {
    color: #bababa !important;
    font-style: italic;
}

.react-select__value-container.react-select__value-container--is-multi.react-select__value-container--has-value.css-1hwfws3::-webkit-scrollbar {
    width: 7px;
}

.ajusta-texto-dia {
    font-size: 10px;
    padding: 0;
    margin: 0;
    border: 0;
    top: 0;
}

@media only screen and (max-width: 985px) {
    .lista-modal li.active {
        color: white;
        background: var(--cor-primaria);
    }
}

@media only screen and (max-width: 992px) {
    .botoes-animation {
        justify-content: end;
        display: flex;
    }
}

.color-button {
    background-color: var(--cor-primaria);
    color: white !important;
}

.ajuste {
    justify-content: end !important;
}

@media only screen and (min-width: 992px) {
    .ajusta-switch {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ajusta-switch-operadora {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
    margin-left: 10px !important;
}



.socio-selecionado {
    /* background-color: darkgrey; */
    color: black;
    /* border: 1.5px solid red !important; */
    border-left: 4px solid black !important;

}

.espacamento {

    display: flex !important;
    justify-content: space-between !important;
}

.selecao {

    background-color: var(--cor-primaria-rgba08);
    color: white;
    border-radius: 50%;
}

tr.titulo-1 {
    background-color: #f1f1f1;
    font-size: 15px;
}

tr.titulo-2 {
    font-size: 13px;
}

.table-agrupamento .td-data {
    padding: .2rem .6rem .2rem .6rem !important;
    font-size: 0.75rem;
}

.btn-left-0 {
    left: 0px !important;
    position: fixed;
}

.socio-confirmado {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    background-color: #f3f3f3;
}

.card-troca-socio {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.50rem;
}

.new-tooltip {
    border: 1px solid rgb(131, 12, 12) !important;
    transform: translate3d(890px, 300px, 0px) !important
}

.ajusta-input-troca-socio {

    margin-top: 15px !important;
    margin-left: -15px !important;

}

.versao-app {
    font-size: 12px;
    color: #ccc !important;
    font-family: Arial, Helvetica, sans-serif;
}

.div-versao {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    padding-left: 10px;
    padding-bottom: 20px;
    z-index: 9999999;
}

.floating-menu {
    text-align: center;
    /* position: absolute;
	right: 8px;
	bottom: 8px; */
    /* height: 42px;
	text-align: right; */
}

.floating-menu button {
    text-align: center;
    margin: 5px
}

.floating-menu.open {
    height: 150px;
    overflow: hidden;
}

.floating-menu.open .floating-menu-item {
    margin-top: 8px;
}

.floating-menu-item label {
    display: inline-block;
    margin-right: 8px;
    padding: 4px 10px;
    border-radius: 12px;
    background: rgba(0, 0, 0, .25);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.floating-menu.open .floating-menu-item label {
    opacity: 0.4;
}

.floating-menu-icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    vertical-align: middle;
    background: #e53935;
    border-radius: 50%;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    text-align: center;
}

.floating-menu-icon .material-icons {
    font-size: 32px;
    color: #fff;
    vertical-align: middle;
}

.icone-alinhado {
    margin-top: 10px !important;
    line-height: 0px !important;

}

.link {
    color: var(--cor-primaria);
}

.link:hover {
    cursor: 'pointer';
    text-decoration: underline;
}

.tabela-linha-destaque tbody tr:hover {
    background-color: #f5f5f5;
}

@media only screen and (max-width: 1060px) and (min-width: 992px) {
    .btn-filter {
        padding: 5px 15px !important;
    }
}

.justify-left {
    justify-content: left;
}

.span-button {
    background: #d7d7d7;
    color: #4b4b4b;
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 20px;
    font-size: 11px;
    white-space: nowrap;
    cursor: pointer;
}

.background-botao-troca {

    background-color: #f8f8f8 !important;
}

.ml-15 {

    margin-left: 15px;
}



.troca-socio-float {
    max-height: 400px;
}

.input-simples,
.input-simples:focus-visible,
.input-simples:focus {
    border: none;
    outline: none;
    background-color: transparent;
}

.bottom-tooltip .tooltip-inner {
    position: absolute !important;
    width: 170px;
    left: -120px
}

.titulo-modal {
    font-weight: bold !important;
    font-size: 16px;
    margin-bottom: 10px;
}


@media only screen and (max-width: 600px) {
    .titulo-modal {
        font-size: 14px;
        margin-bottom: 10px;
    }
}


.bottom-stuff {
    position: relative;
}

.bottom {
    position: fixed;
    width: 90%;
    bottom: 0;
    padding-bottom: 10px;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
}

div[class|="control"] {
    border-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    min-height: 44px;
}

.ordenavel,
th {
    cursor: pointer;
}

.excluir-active {
    background-color: #eee;
}


.linha-bottom-relatorio {
    border-bottom: 1px solid #ddd;
}

.linha-bottom-periodo-gozo {
    border-bottom: 1px solid #ddd;
    margin-bottom: 13px;
}

.table-scroll-tbody tbody {
    display: block;
    overflow: auto;
}

.table-scroll-tbody thead,
.table-scroll-tbody tbody tr {
    display: table;
    table-layout: fixed;
}

.table-card {
    margin-bottom: 5px !important;
}

.table-separate-4 {
    border-collapse: separate;
    border-spacing: 0 4px;
}

.table-ptb-0 td,
.table-ptb-0 th {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.scroll-horizontal::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
}

.scroll-horizontal::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

.scroll-horizontal {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    margin-left: 15px;
    margin-right: 15px;
    align-items: center;
    display: flex;
    margin-bottom: 5px;
}

.scroll-horizontal-div {
    display: inline;
    border-radius: 20px;
    padding: 5px 10px;
    background-color: #eee;
    margin-right: 5px;
    align-items: 'center';
    justify-content: 'flex-end';
}

.footer-only-print {
    display: none;
}

.footer-only-print-visualizacao {
    display: none;
}

@media print {
    .card-body {
        background-color: '#fff' !important;
    }

    .impressao {
        background-color: '#fff' !important;
    }

    .d-block-print {
        display: inline !important;
    }

    .opacity-1-print {
        opacity: 1;
    }

    .d-none-print {
        display: none;
    }

    body {
        -webkit-print-color-adjust: exact;
        margin: -10px -10px 10px -10px;
        background-color: '#fff' !important;
    }

    .group-title-consulta .ordenavel::before,
    .group-title-consulta .ordenavel::after {
        display: none;
    }

    .group-title-consulta .coluna+.coluna {
        border-left: unset !important;
        border-left: unset !important;
    }

    .impressao.landscape {
        zoom: .72
    }

    .impressao.portrait {
        /* zoom: .8 */
    }

    .footer-only-print {
        display: block !important;
        margin-left: 20px;
    }

    .footer-only-print-visualizacao {
        display: block !important;
        margin-left: 0px;
    }

    .my-div {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .my-div::after {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
    }

    .relatorio-lote .table-agrupamento .td-data,
    .relatorio-lote .group-title .coluna {
        font-size: 0.65rem !important;
    }
}

.my-div {
    page-break-inside: avoid;
}

@media print {
    .repetir {
        display: table-header-group;
    }
}

#header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    /* ajuste a altura de acordo com sua necessidade */
    background-color: #ccc;
    /* ajuste a cor de fundo de acordo com sua necessidade */
    text-align: center;
    padding: 10px;
}

.repetir {
    width: 100%;
    height: 50px;
    /* altura mínima */
    background-color: #f2f2f2;
    /* opcional */
    display: table-header-group;
}

.table-socio-agenda td {
    vertical-align: top;
}

.table-socio-agenda tbody>tr>td:first-child {
    border-right: unset !important;
    width: unset !important;
}

.ellipsis-overflow {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.red-color {
    color: red
}

.dimgrey-color {
    color: dimgrey
}

.flip-icon {

    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);

    transform: rotate(180deg);

}

.capitalize {
    text-transform: lowercase !important;
}

.testando {
    padding-left: 53px !important;
}

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before {
    background-color: #94d1ff !important;
}

.executantes-internos-float {
    /* min-width: 1328px; */
}

.scroll-x {
    /* overflow-y: hidden;
    overflow-x: auto; */
}

.scroll-x::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

.scroll-x::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
}

.scroll-x::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

.justify-center-vertical {
    display: flex !important;
    justify-content: center !important;
}

.bt-black-1 {
    border-top: 1px dotted #000;

}

.color-gray-relatorio {
    background-color: #dadada !important;
}

.color-gray-coluna {
    background-color: #f1f1f1 !important;
    color: #000 !important;
}

.bb-black-1:last-child {
    border-bottom: 1px solid #000;

}

.bt-guia-correcao {
    border-bottom: 1px solid #000 !important;
}

.titulo-agrupamento {
    font-size: .7rem;
    padding: 0.3rem;
}

.icone-header {
    padding-left: 15px;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    display: flex;
}

.icone-header-switch {
    padding-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
}

.color-icone-header {
    cursor: 'pointer';
    color: '#c9c9c9';
    font-size: 20;
}

.pointer {
    cursor: 'pointer'
}

.guia-status {
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 12px;
    white-space: nowrap;
    color: white
}

.guia-status-ellipsis {
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 12px;
    white-space: nowrap;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px !important;
}

.guia-status-ellipsis-responsivo {
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 12px;
    white-space: nowrap;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60px !important;
}

.guia-complementar {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 10px;
    margin-left: -15px;
    margin-top: -13px;
}

.legenda {
    color: white;
    font-size: 11px;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 11px;
    margin-right: 5px;
    white-space: nowrap;
}

.min-width-125 {
    min-width: 125px !important;
}

.min-width-100 {
    min-width: 100px !important;
}

.min-width-170 {
    min-width: 170px !important;
}

.guia_none {
    display: none;
}

.guia-contents {
    display: contents;
}

.mt-8 {
    margin-top: 8px;
}

.valores-recebidos {
    width: 750px;

}

.line-vertical {
    height: 200px;
    border-right: 1px solid #ccc;
}

.valor_total {
    display: flex;
    justify-content: flex-end;
    margin-right: 130px;

}

.valor_total_geral {
    display: flex;
    justify-content: flex-end;
    padding: 50px;

}

.valor_retencao_total {
    margin-left: 485px;

}

.border-select {
    border: 1px solid;
    border-radius: 15px;
    color: #CCC
}

.valor-indicador-certo {
    box-shadow: 0 1px 15px 0 1px 6px !important;
    color: green !important;
    /* border: 1px solid green !important; */
}

.valor-indicador-errado {
    box-shadow: 0 1px 15px 0 1px 6px !important;
    color: red !important;
}

.valor-indicador-azul {
    box-shadow: 0 1px 15px 0 1px 6px !important;
    color: blue !important;
    /* border: 1px solid green !important; */
}

.dp-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}

.table-width {}

.botao-financeiro {
    background-color: #fff !important;
    color: var(--cor-primaria) !important;
    border: 1px var(--cor-primaria) solid !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.color-valor-liquidacao-acima {
    color: #0755f2 !important
}

.color-valor-liquidacao-normalizado {
    color: #01af2d !important
}

.color-valor-liquidacao-abaixo {
    color: #f20707 !important
}

.valor-total-lancamento {
    border-top: 1px #bababa solid;
    margin: 20px
}

.box-liquidacao-parcelado {
    border: 1px solid #dadada;
    padding: 25px;
    border-radius: 25px;
}

.anotacoes {
    padding: 0 15px 10px 15px;
    width: 100%;
}

.anotacoes-liquidacao {
    background-color: #f1f1f1;
    padding: 7px;
    width: 100%;
    font-style: italic;
    vertical-align: baseline;
    border-width: 0px 0px 0px 5px;
    border-color: #020029;
    border-style: solid;
}

.anotacoes-liquidacao h6 {
    font-size: 13px !important;
    margin-bottom: 0px;
    color: #888;
    padding-top: 5px;
}

.anotacoes-liquidacao .icon-Note {
    font-size: 20px !important;
    margin-right: 5px;
}

.parcelado {
    margin-left: 2.25rem !important;
    margin-top: -35px;
}

.botao-liquidacao {
    margin-top: 25px;
    margin-left: 10px;
    outline: none;
    border: unset !important;
    font-size: 5px;
    /* padding: 10px; */
    cursor: pointer;
    color: #000;
    background-color: transparent;
    color: #000;
    border-radius: 50px;
}

.separator-liquidacao {
    border-top: 2px solid #dee2e6;
    padding: 10px;
}

.valor_total_liquidacao {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}

.row-selected {
    background-color: #bababa !important;

}

.tab-lancamento {}

.tab-p-1 {
    padding: 1rem !important;
    margin-left: 8px;
    margin-top: -8px;
}

.tab-tittle {
    padding: 30px;
}

.z-index-max {
    z-index: 999999 !important;
}

.indicador-parcelado {
    display: flex;
    margin-right: 31px;
    margin-top: -21px;
}

@media print {
    .rmv-link {
        color: #000 !important;
        text-decoration: none !important;
        background-color: transparent !important;
    }

    .tr-visto {
        visibility: visible !important;
    }

    .nao-quebrar {
        page-break-inside: avoid !important;
        widows: 2;
        orphans: 2;
    }
}

.box-fechamento {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}


.alert-fechamento {
    margin-left: 514px;
    margin-top: 50px !important;

}

.retangulo {
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    border-radius: 5px;
    position: relative;
    margin: auto;
    top: 50%;
}

.retangulo-ass {
    width: 340px;
    height: 80px;
    border: 1px solid #000;
    border-radius: 2px;
    /* position: relative;
    margin: auto;
    top: 50%; */
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 5px;
}

.tr-movimento-receita {
    border-bottom: 1px #f1f1f1 solid;
}

.div-assinatura {
    display: grid;
    justify-content: flex-end;
}

.quebra-de-pagina {
    page-break-after: always;
}

.nao-quebrar {
    page-break-inside: avoid !important;
}

.quebrar-dentro {
    page-break-inside: always !important;
}

.fs-11 {
    font-size: 11px;
}

.flex-direita-margin-right {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
}

.red-bg {
    color: red !important;
}

.blue-bg {
    color: blue !important;
}

.tr-visto {
    visibility: collapse;
}

.fechamento-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

}

.fechamento-container-personalizado {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

@media (max-width: 1800px) {
    .fechamento-container-personalizado {
        justify-content: space-between !important;
        /* Altera para o alinhamento padrão */
    }
}

.tabelas-fechamento {
    flex-basis: 45%;
    padding: 15px;
    max-height: 50%;
    text-align: center;

}

.width-50 {
    width: 51%;
}

.valor-total-span {
    min-width: '100px';
    font-weight: 'bold';
    display: 'inline-block'
}

.tipo-lancamento span ::before {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    width: 0.5rem;
    height: 0.5rem;
    border-radius: 999px;




}

.nowrap {
    white-space: nowrap;
}

.div-tabela {
    max-width: 100% !important;
    overflow-x: scroll;
}

.div-tabela th:last-child,
.div-tabela td:last-child {
    position: sticky;
    right: 0;
    box-shadow: 10px 0 5px -5px rgba(0, 0, 0, 1);
}

.div-tabela td:last-child::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, #fff 0%, #f1f1f1 35%, #ddd 100%);
    z-index: -1;
    transform: translateX(-100%);
}

.shadow-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20px;
    box-shadow: 10px 0px 5px -5px rgba(0, 0, 0, 0.3);
}

.total-width {
    width: 100%;
}

.ultima_modificacao {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    /* Cor da bolinha */
    margin-right: 4px;
    /* Espaçamento entre a bolinha e o texto */
}

.status-lancamento {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 10px;
}

.icones-consulta-lancamento {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -24px
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* Esta é uma proporção comum para vídeos, ajuste conforme necessário */
    height: 0;
    overflow: hidden;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.div-botao-tipo_grafico {
    display: flex;
    justify-content: end;
}

.principal-cmb-finclin {
    display: flex !important;
    justify-content: space-between !important;
}

.secondary-cmb-finclin {
    display: flex !important;
    justify-content: flex-start !important;
}

.third-cmb-finclin {
    display: flex !important;
    justify-content: flex-end !important;
}

.limitador-caracteres {
    /* width: 200px;  Defina a largura máxima desejada */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ml-10 {
    margin-left: 10px;
}

.uppercase {
    text-transform: uppercase;
}

.fs-20 {
    font-size: 20px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-10 {
    margin-bottom: 10px
}

.mb-40 {
    margin-bottom: 80px;
}

.flex-column {
    flex-direction: column;
}

.pdb-0 {
    padding-bottom: 0 !important;
}

.mlr-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.text-justify {
    text-align: justify;
}

.mt-65 {
    margin-top: 65px;
}

.mt-100 {
    margin-top: 100px;
}

.landscape-report {
    font-size: '0.7 rem';
}

.items-center {
    align-items: center;
}