.accordion {
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  margin-bottom: 10px;
}

.accordion-header {
  padding: 10px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
}

.accordion-content {
  padding: 10px;
}

.accordion-icon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #666 transparent transparent transparent;
    transition: transform 0.3s ease;
    transform-origin: 50% 25%;
  }

.accordion-icon.open {
  transform: rotate(180deg);
}
.accordion.open .accordion-icon {
    transform: rotate(180deg);
}

  .color-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Estilos para a classe color-dot */
  .color-dot {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
    margin-right: 10px !important;
  }
  .flex{
    display: -webkit-inline-box;
  }