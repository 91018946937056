:root {
    --cor-primaria: rgb(2, 0, 41);
    --cor-primaria-rgba01: rgba(2, 0, 41, 0.1);
    --cor-primaria-rgba08: rgba(2, 0, 41, 0.8);
}

.tab-error{
    box-shadow: 0 1px 15px rgba(255, 0, 0, 0.04), 0 1px 6px rgba(255, 0, 0, 0.04) !important;
    color: red !important;
    border-top: 1px solid red !important;
}

.react-tabs__tab-list {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    border: 0 !important;
}

.react-tabs__tab, .react-tabs__tab--selected {
    background-color: white;
    border-radius: 0!important;
    min-height: 40px;
    padding-top: 12px !important;
}

.react-tabs__tab+.react-tabs__tab {
    border-left: 1px solid #ddd;
}
/* .react-tabs__tab, .error{
    background-color: #ffcbcb !important;
} */

.react-tabs__tab--selected {
    border-top: none !important;
    border: solid 1px var(--cor-primaria) !important;
    border-bottom: none !important;
    background-color: var(--cor-primaria)!important;
    color: white !important;
    font-weight: normal !important;

}

.react-tabs__tab-panel {
    background-color: white !important;
}

@media only screen and (max-width: 700px) {
   
    .icone-tab{
        font-size: 20px;
        display: flex;
        justify-content: center;
        padding-left: 10px !important;
        margin-bottom: 10px !important;
        margin-right: 0px !important;
    }
    .react-tabs__tab {
        min-height: 80px!important;
        display: grid;
        align-content: center;
        text-align: center !important;
    }
    
}
.react-tabs__tab-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: touch;
}

.icone-tab{
    margin-right: 10px !important;
}




.react-tabs__tab-list::-webkit-scrollbar {
    width: 0px;
    height: 4px;
}

.react-tabs__tab-list::-webkit-scrollbar-thumb {
    background: #ddd;
}

.react-tabs__tab-list::-webkit-scrollbar-thumb:hover {
    background: #eee;
}

