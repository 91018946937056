:root {
    --cor-pendente: #db8318;
    --cor-agendado: #007cad;
    --cor-confirmado: #00ad62;
    --cor-finalizado: #555;
    --cor-cancelado: #a1a1a1;
    --cor-recusado: #ad0000;
    --cor-escalado: #99900e;
}

.agenda tbody>tr>td:first-child {
    border-right: 2px solid rgb(126, 126, 126);
    width: 10px;
}

.agenda .paginacao {
    display: none !important;
}

.agenda .dias {
    height: 40px;
    margin-bottom: 20px;
}

.agenda .span-hora {
    margin-block-end: 5px;
}

@media only screen and (max-width: 767) {
    .span-hora.vertical-center {
        display: unset !important;
    }

}

@media only screen and (max-height: 860px) {

    .card-right {
        height: 90% !important;
    }
}

.card-right {
    position: fixed;
    top: 70px;
    right: 0px;
    background-color: white;
    height: calc(100% - 50px);
    border-left: 1px solid #f3f3f3;
    width: 300px;
    padding-top: 0px;
    box-shadow: 20px 3px 30px rgba(150, 150, 150, 0.1), 0 0 0 rgba(100, 100, 100, 0.1);
    transition-property: margin;
    transition-duration: .5s;
    transition-timing-function: ease-out;
    transition-delay: 0;
    z-index: 10 !important;
    padding: 20px;
    margin-right: -350px;
    overflow: auto;
}

.float-header .card-right {
    top: 135px !important;
    height: calc(95% - 70px) !important;
}

@media only screen and (max-width: 1150px) {
    .agenda-content.filtro-ativo {
        width: 100% !important;
    }
}

.filtro-ativo {
    margin-right: 0px
}

.margin-pequena+.margin-pequena {
    margin-top: 15px !important;
}

.agenda-content.filtro-ativo {
    width: calc(100% - 300px);
}

.agenda-content .card-pequeno {
    padding: 15px 0px;
}

.agenda-content .div-dia {
    padding: 7px 10px;
    background-color: #ddd;
    color: #444;
    margin-bottom: 10px;
}

.agenda-content .div-dia.hoje {
    background-color: #020029;
    color: white;
}

.agenda-content .separator.mb-5 {
    margin-bottom: 20px !important;
}

.agenda-content .titulo-filtro {
    display: flex !important;
    align-content: center !important;
    color: #909090;
    margin-bottom: 10px;
}

.agenda-content .titulo-filtro>button {
    height: 40px;
}

.agenda-content .card-pequeno .card {
    padding: 10px;
    min-height: 70px;
    font-size: 12px;
}


.detalhes {
    border: 1px solid #ddd;
    padding: 30px 15px;
    border-radius: 20px;
}

.item-detalhes+.item-detalhes {
    margin-top: 20px;
}

.agenda-content .calendario {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.agenda-content .agenda .btn-acao {
    font-size: 18px;
}

.agenda-content .div-dia span {
    font-size: 13px;
}

/*agenda 2.0*/
.card-row-agenda {
    padding: 0px !important;
}

.card-row-agenda .column {
    border-top: 1px solid #eee;
    padding: 5px !important;
}

.column.border-l+.column.border-l {
    border-left: 1px solid #eee;
}

.card-row-agenda.card {
    min-height: 30px;
}

.p-5-after+.p-5-after {
    padding-top: 7px !important;
}

.card-row-agenda .btn-acao {
    font-size: 17px !important;
    padding: 4px;
}

.card-row-agenda .icone:hover {
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: unset !important;
    -ms-transition: all 200ms ease-in;
    -ms-transform: unset !important;
    -moz-transition: all 200ms ease-in;
    -moz-transform: unset !important;
    transition: all 200ms ease-in;
    transform: unset !important;
}

.card-row-agenda .icone:last-child {
    margin-right: 15px !important;
}

.tag {
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 20px;
    font-size: 11px;
    white-space: nowrap
}

.tag-confirmado {
    background: #00ad62;
    color: white;
}

.tag-confirmado-outline {
    border: 1px solid #00ad62;
    color: #00ad62;
}

.tag-cancelado {
    background: #a1a1a1;
    color: white;
}

.tag-cancelado-outline {
    border: 1px solid #a1a1a1;
    color: #a1a1a1;
}

.tag-recusado {
    background: #ad0000;
    color: white;
}

.tag-recusado-outline {
    border: 1px solid #ad0000;
    color: #ad0000;
}

.tag-pendente {
    background: #db8318;
    color: white;
}

.tag-escalado {
    background: #99900e;
    color: white;
}

.tag-escalado-outline {
    border: 1px solid #99900e;
    color: #99900e;
}

.tag-pendente-outline {
    border: 1px solid #db8318;
    color: #db8318;
}

.tag-n-enviado {
    background: #007cad;
    /* #8a6d07 */
    color: white;
}

.tag-n-enviado-outline {
    border: 1px solid #007cad;
    color: #007cad;
}

.tag-agendado {
    background: #007cad;
    /* #8a6d07 */
    color: white;
}

.tag-lembrete {
    background: #E0954C;
    /* #8a6d07 */
    color: white;
    cursor: pointer;
}

.tag-lembrete:hover {
    text-decoration: underline;
}

.tag-resumo {
    background: #00ad62;
    /* #8a6d07 */
    color: white;
    cursor: pointer;
}

.tag-resumo:hover {
    text-decoration: underline;
}

.tag-resumo-grupos {
    background: #99900e;
    /* #8a6d07 */
    color: white;
    cursor: pointer;
}

.tag-resumo-grupos:hover {
    text-decoration: underline;
}
.tag-resumo-escalas {
    background: #ad0000;
    /* #8a6d07 */
    color: white;
    cursor: pointer;
}

.tag-resumo-escalas:hover {
    text-decoration: underline;
}

.tag-registro {
    background: #4c97e0;
    /* #8a6d07 */
    color: white;
    cursor: pointer;
}

.tag-registro:hover {
    text-decoration: underline;
}


.tag-agendado-outline {
    border: 1px solid #007cad;
    color: #007cad;
}

.tag-finalizado {
    background: #555;
    color: white;
}

.tag-finalizado-outline {
    border: 1px solid #555;
    color: #555;
}

.tag-substituído {
    background: #007bff;
    color: white;
}

.tag-substituído-outline {
    border: 1px solid #007bff;
    color: #007bff;
}

.log-indicator {
    width: 13px;
    height: 13px;
    border: 2px solid #145388;
    border-radius: 14px;
    display: inline-block;
}

.border-agendado {
    border-color: var(--cor-agendado);
}

.border-confirmado {
    border-color: var(--cor-confirmado);
}

.border-cancelado {
    border-color: var(--cor-cancelado);
}

.border-recusado {
    border-color: var(--cor-recusado);
}

.border-finalizado {
    border-color: var(--cor-finalizado);
}

.border-escalado {
    border-color: var(--cor-escalado);
}

.border-pendente {
    border-color: var(--cor-pendente);
}

.fs-13 {
    font-size: 13px !important;
}

.color-gray {
    color: #5d5d5d;
}

.p-unset {
    position: unset !important
}

.agenda+.div-dia {
    margin-top: 10px;
}

.procedimentos {
    margin-bottom: 3px !important;
}

.col-hora {
    min-height: 21px;
}

.calendario-agendamentos .dia-icone-agendamento {
    font-size: 25px;
}

.calendario-agendamentos {
    cursor: pointer;
    height: 100%;
}

.calendario-agendamentos:hover {
    color: #007bff;
}

.lista-simples-agendamentos {
    padding-left: 0px;
}

.lista-simples-agendamentos li {
    display: block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 10px;
}

.lista-simples-agendamentos .col-hora {
    align-items: center;
    display: flex;
    margin-bottom: 5px;

}

.switch-envio-card {
    position: relative;
    width: 0px;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.1s;
    animation: slide 0.5s forwards;
    animation-delay: 0.1s;
    cursor: pointer;
    /* box-shadow:20px 20px 50px 10px pink inset;  */
    /* box-shadow: 30px 0px 20px -10px #484583 inset; */
}

.switch-envio-card.hide {
    position: relative;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.1s;
    animation: slide 0.5s forwards;
    animation-delay: 0.1s;
    cursor: pointer;
    /* box-shadow:20px 20px 50px 10px pink inset;  */
    /* box-shadow: 30px 0px 20px -10px #484583 inset; */
}

.switch-center {
    margin: 0 0 0 10px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.slide-up-bottom-div {
    position: fixed;
    right: 20px;
    bottom: 17px !important;
    -webkit-animation: slideUp 0.5s forwards;
    -webkit-animation-delay: 0.1s;
    animation: slideUp 0.5s forwards;
    animation-delay: 0.1s;
    cursor: pointer;
    z-index: 11;
}

.slide-middle-div {
    position: fixed;
    -webkit-animation: slideUp 0.5s forwards;
    -webkit-animation-delay: 0.1s;
    animation: slideUp 0.5s forwards;
    animation-delay: 0.1s;
    cursor: pointer;
    z-index: 1;
}

.slide-middle-right-div {
    right: 20px;
}

.slide-middle-left-div {
    left: 120px;
}


.slide-up-bottom-div.filtro-aberto,
.slide-middle-div.filtro-aberto {
    right: 330px !important;
}

.no-border {
    border: unset !important;
}

.justfy-center {
    justify-content: center;
    ;
}

.card-envio-row {
    display: flex;
    min-height: 50px;
}

.proced-modal+.proced-modal {
    border-top: 1px solid #ddd
}

@-webkit-keyframes slide {
    100% {
        left: 0;
        width: 100px;
    }
}

@keyframes slide {
    100% {
        left: 0;
        width: 100px;
    }
}

.btn-middle-left{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius: 50px !important;
    background-color: #fff !important;
    border-color: #fff !important;
    margin-left: 0 !important;
    padding: 25px 15px 25px 10px !important;
    box-shadow: 5px 5px 15px 5px #ddd;
}

.btn-middle-right{
    border-bottom-left-radius: 50px !important;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: #fff !important;
    border-color: #fff !important;
    margin-right: 0 !important;
    padding: 25px 15px 25px 10px !important;
    box-shadow: 5px 5px 15px 5px #ddd;
}

.btn-scroll-top span.icon-Arrow-Left {
    position: absolute;
    left: 0px;
    bottom: 17px;
    color: black;
    font-weight: bold;
}

.btn-scroll-top span.icon-Arrow-Right {
    position: absolute;
    right: 0px;
    bottom: 17px;
    color: black;
    font-weight: bold;
}

/* .lista-selecao li {
    display: inline-block !important;
    margin-bottom: 12px !important;
    margin-right: 3px !important;
} */