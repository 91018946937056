.mensagem {
  font-size: 12px;
  color: black;
  padding-right: 20px;
  text-align: left;
  width: 270px !important;
  min-width: 200px !important;
  position: fixed;
  float: left;
  margin-top: 6px;
}

.progresso {
  height: 40px !important;
  position: fixed;
  margin-left: 120px;
  margin-top: -8px;
}


.percentual {
  font-size: 11px;
  color: black;
  position: fixed;
  float: right;
  margin-left: 130px;
  margin-top: 6.5px !important;
}

.concluido {
  font-size: 10px !important;
  margin-left: 128px !important;
}

.snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 350px;
  /* Set a default minimum width */
  min-height: 55px;
  /* Set a default minimum width */
  margin-left: -125px;
  /* Divide value of min-width by 2 */
  background-color: #white;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 2px;
  /* Rounded borders */
  padding: 16px;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 1;
  /* Add a z-index if needed */
  left: 50%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */

.snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.snackbar.ocultando {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadeout 2.5s;
  animation: fadeout 2.5s;
}

.snackbar.oculto {
  visibility: hidden;
  /* Show the snackbar */
}

/* Animations to fade the snackbar in and out */

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.li-arquivos  {
  list-style-type: none;
  margin: 15px;
  padding: 5px;
  background-color: white !important;
  border: 1px solid #ced4da;
  border-radius: 0.5rem !important;
  min-height: 40px;
  width: calc(100% -10px);
  display: flex;
  flex-direction: row;
  justify-content: right !important;
  align-self: flex-end !important;
}


.btn-download-arquivo, .btn-excluir-arquivo{
  color: white;
  background-color: #04022B !important;
  height: 30px !important;
  width: 30px !important;
  border: none;
  border-radius: 15px !important;
  justify-content: right !important;
  align-self: flex-end !important;
  margin-right: 5px;
  margin-top: 1px;
  -webkit-transition: width 1s;
  transition: width 1s;
}

.icon-Download-fromCloud, .icon-Download-fromCloud {
  font-size: 12px;
}

.icon-Yes-style {
  padding-top: 2px;
  font-size: 12px;
}
.dv-3{
  margin-left: auto;
}
.dv-1{
  padding-top: 4px !important;
  height: 100%;

}
.dv-2{
  margin-top: 8px;
}

.titulos{
  padding-left: 10px;
  position: relative;
  width: 100%;
}
.row-arquivos{
    width: 100% !important;
    min-width: 100% !important;
}
.ul-arquivos {
  z-index: 9999999 !important;
  padding: 0px;
  width: 100%;

}

.icone-list {
  font-size: 20px;
  padding-left: 4px;
  align-items: center;
}

.label-nome-arquivo{
  padding-left: 10px;
}

.files-dropzone {
  background-color: #fff;
  min-height: 82px;
  border: 1px solid #ced4da;
  border-top-color: rgb(206, 212, 218);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  color: #495057;
  padding: 0.75rem !important;
  padding-top: 0.75rem !important;
  padding-right: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  padding-left: 0.75rem !important;
  font-size: 0.8rem !important;
  border-radius: 0.1rem !important;
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
}

.icone-upload {
  font-size: 30px;
  color: #a5a8aa;
  z-index: 1 !important;
}

.titulo-arquivos{
  font-size: 12;
  position: absolute;
  padding-top: 10px;
  padding-left: 10px;
}


.titulos:hover{
  cursor: pointer;
}


.progresso-menor {
  height: 30px !important;
  width: 40px !important;
  padding-right: 5px !important;
}

.percentual-menor {
  font-size: 11px;
  color: black;
  position: absolute;
  margin-left: -1130px !important;
  margin-top: 6.5px !important;
  z-index: 999;
}



.concluido-menor {
  font-size: 10px !important;
  margin-left: 128px !important;
}


.percentual-list {
  position: absolute;
  z-index: 9999;
  padding-top: 11px;
  padding-left: 5px;
  font-size: 10px;
  
}


.percentual-list-concluido {
  font-size: 9px;
  color: green;
}

.progresso-menor .CircularProgressbar-text{
  font-size: 28px !important;
  height: 100px !important; 
  padding-top: 20px !important;
}

.widget-mobile{
    text-align: center;
    height: 40px !important; 
    width: 40px !important; 
    margin-right: 15px !important;
    border-radius: 25px !important;

}

.espaco-icones{
  margin-bottom: 5px !important;
}

.text-mobile{
  font-size: 13px !important;
}

@media only screen and (max-width: 600px) {
.text-mobile{
  font-size: 15px !important;
  padding-top: 5px !important;
  padding-bottom: 15px !important;
}

.widget-mobile{
    text-align: center;
    height: 60px !important; 
    width: 60px !important; 
    margin-right: 15px !important;
    border-radius: 40px !important;
    font-size: 25px !important;
}
.icon-Download-fromCloud, .icon-Yes-Style {
  font-size: 25px !important;
  padding-top: 15px !important;
}

.progresso-menor {
 margin-top: -10px !important;
 margin-right: 7px !important;
 margin-left: 6px !important;
 height: 64px !important;
 width: 64px !important;
}
}