:root {
    --meridiem-bg-color:var(--cor-primaria);
    --meridiem-text-color: white;
    /* --clock-wrapper-bg:#e0ded7; */
    --cor-primaria: rgb(2, 0, 41);
    /* --main-box-shadow: 0 3px 2px var(--cor-primaria), 0 6px 6px var(--cor-primaria); */
    /* --main-font-family: 'Roboto', sans-serif; */
    --top-selected-color:white;
    --top-bg:var(--cor-primaria);
    --hand-circle-center:var(--cor-primaria);
    --hand-circle-outer:var(--cor-primaria);
    --hand-minute-circle:var(--cor-primaria);
    --done-text-color:var(--cor-primaria);
    --dropdown-hover-bg:var(--cor-primaria);
    --done-border-top: 1px solid var(--cor-primaria);
    --hand-line-color:var(--cor-primaria);
    /* --clock-wrapper-bg:var; */
    /* --clock-bg: red; */
    /* --top-meridiem-color:red; */
}
.react-timekeeper.css-nakgy8-TimeKeeper{
    z-index: 99999;
    position: absolute;
}
.tooltip-input-modal.show.tooltip{
    z-index: 0;
}
.time-center{
    text-align: center;
    /* min-height: 500px; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 80px; */
    /* border-radius: 3px; */
}


.react-timekeeper__done-button {
    font-size: 0px !important; 
  }
  
  .react-timekeeper__done-button::after {
    content: "Confirmar";
    font-size: 12px !important; 
  }


.sem-tooltip{
    z-index:0 !important;
    transform: translate3d(0px, 0px, 0px)!important;
}
/* .provisoria{
    border: 1px rgb(131, 12, 12) solid;
    background: #fff;
}
.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem;
} */
.container-2{
    text-align: center;
    /* min-height: 500px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* padding-top: 80px; */
    /* border-radius: 3px; */
}
