/* .card-rotinas.card+.card {
    margin-top: 20px;
} */

.data-card{
    display: flex;
    align-items: center;
}

.data-card button,
.data-card span {
    font-size: .8rem !important;
}

.card-rotinas>.card-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    padding: 1.25rem 1.25rem 0;
    font-size: .8rem !important;
    padding-bottom: 5px;
    border-bottom: .5px solid #eee;
}

.card-rotinas>.card-title>h5 {
    margin-bottom: 0 !important;
}

.paginacao-rotinas>.pagination-button,
.data-card>div>.pagination-button {
    padding: 5px;
}

.paginacao-rotinas>.icone-pagination,
.data-card>div>.icone-pagination {
    margin-right: 5px;
    margin-left: 5px;
}

.titulo-rotinas {
    font-weight: bold;
}

.overflow-rotina {
    overflow: auto;
    height: 90%;
}

.card-rotinas .card {
    flex: 1 !important;
    max-height: 30%;
}

.card-rotinas>.card-body {
    height: 260px;
}

.rotina-dados {
    font-size: .8rem;
}

.titulo-estabelecimento {
    font-size: 1.1rem;
    flex: 2
}

.paginacao-rotinas {
    text-align: right;
}

.data-geral {
    margin-right: 90px;
}

@media only screen and (max-width: 645px) {
    .titulo-estabelecimento {
        font-size: .9rem;
    }
}

@media only screen and (max-width: 990px) {
    .paginacao-rotinas {
        text-align: center;
    }

    .data-geral {
        margin-right: 0px;
    }
}