.data-grid th,
.data-grid td {
    padding: .4rem .5rem;
    text-align: left;
    vertical-align: middle;
    background: white;
    border-bottom: 1px solid rgba(224, 224, 244, 1);
}

.data-grid thead th {
    border-bottom: 1px solid rgb(126, 126, 126);
    border-top: unset;
}

@media only screen and (max-width:1590px){

    .ajusta-dropdown .btn-acao {
        outline: none;
        border: unset !important;
        font-size: 16px !important;
        padding: 10px !important;
        cursor: pointer !important;
        border-radius: 50px !important;
        background-color: white !important;
        border: 1px #ddd solid !important;
        color: rgb(2,0,41)
    }

    .btn-acao:hover {
        border-radius: 50% !important;
        background-color: #00000014 !important;
        text-decoration: unset !important;
        cursor: pointer !important;
        color: #0056b3 !important;
        outline: 0 !important;
    }
    .btn-acao-alternativo:hover {
        border-radius: 50% !important;
        background-color: #00000014 !important;
        text-decoration: unset !important;
        cursor: pointer;
        color: #0056b3 !important;
        outline: 0 !important;
    }
}
.icon-consulta {
    padding: 10px;
    font-size: 22px;
    margin: 0 5px;
    color: #020029;
    -moz-transition: font-size 0.3s;
    -webkit-transition: font-size 0.3s;
    transition: font-size 0.3s;
    width: 100px;
}

.dropdown__content {
    z-index: 9999999999999 !important;
}

.icon-consulta:hover {
    border-radius: 50%;
    background-color: #00000014 !important;
    text-decoration: unset !important;
    cursor: pointer !important;
    color: #0056b3 !important;

}

.icone {
    display: inline-block;
    border: 0;
    position: relative;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
}

.icone-pagination {
    display: inline-block;
    border: 0;
    position: relative;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    margin: 0 15px;
}

.icone-pagination:hover {
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.3);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.3);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.3);
    transition: all 200ms ease-in;
    transform: scale(1.3);
    border-radius: 50%;
    background-color: #00000014;
    text-decoration: unset !important;
}

.icone:hover {
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.3);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.3);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.3);
    transition: all 200ms ease-in;
    transform: scale(1.3);
}

.numero-pagina {
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.3);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.3);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.3);
    transition: all 200ms ease-in;
    transform: scale(1.3);
    margin-left: 10px;
}

.th-acoes {
    text-align: center !important;
    width: 300px;
    padding-right: 30px;
}

.pagination-button {
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    background-color: white;
    border-radius: 50px;
}

.pagination-button:first-of-type {
    margin-right: -10px;
    color: var(--cor-primaria);
    font-weight: 700;
}

.pagination-button:last-of-type {
    margin-left: -10px !important;
    color: var(--cor-primaria);
    font-weight: 700;
}

.btn-acao {
    outline: none;
    border: unset !important;
    font-size: 22px;
    padding: 10px;
    cursor: pointer;
    color: #000;
    background-color: white;
    color: #000;
    border-radius: 50px;
}

.btn-acao-alternativo {
    outline: none;
    border: unset !important;
    font-size: 22px;
    padding: 10px;
    cursor: pointer;
    color: #000;
    background-color: white;
    color: #000;
    border-radius: 50px;
}

.card-acoes-dropdown{
    display:inline-table;
    background-color: white;
}
.btn-acao:hover {
    border-radius: 50%;
    background-color: #00000014 !important;
    text-decoration: unset !important;
    cursor: pointer !important;
    color: #0056b3 !important;
    outline: 0 !important;
}
.btn-acao-alternativo:hover {
    border-radius: 50%;
    background-color: #00000014 !important;
    text-decoration: unset !important;
    cursor: pointer;
    color: #0056b3 !important;
    outline: 0 !important;
}

.btn-acao:focus {
    outline: 0;
}
.btn-acao-alternativo:focus {
    outline: 0;
}

.responsive-header {
    display: none;
    font-weight: bold;
}

.div-filtro-datagrid {
    position: fixed;
    z-index: 1030;
    top: 93%;
    right: 0%;
    padding-right: 40px;
    display: none;
}

.filtro-datagrid-responsive {
    background-color: var(--cor-primaria) !important;
    color:#fff
}

.paginacao-responsive{
    display: none;
}

.ajusta-dropdown{
    padding: 5px;
    box-shadow: -2px 4px 12px 4px var(--cor-primaria-rgba01);
    border: 1px #ddd solid;

}

.ajusta-dropdown-consulta-guia{
    padding: 10px;
    box-shadow: -2px 4px 12px 4px var(--cor-primaria-rgba01);
    border: 1px #ddd solid;

}

.ajusta-modal-ordenamento{

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 21px;

}

.div-filtro-datagrid {
    display: block !important;
}
@media only screen and (max-width: 1375px) and (min-width:1335px){
    .inline-table{
        display:inline-table;

    }
}

@media only screen and (max-width: 1334px) {
    .paginacao-responsive{
        display: block;
    }

    table .data-grid,
    .data-grid thead,
    .data-grid tbody,
    .data-grid th,
    .data-grid td,
    .data-grid tr {
        display: block;
    }

    .data-grid thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .data-grid td {
        position: relative;
        /* padding-left: 50%; */
        /* text-align: center; */
    }

    .responsive-content{

        text-align: end !important;
    }

    .data-grid td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    .data-grid tbody tr .responsive-td {
        justify-content: space-between;
        display: flex;
    }

    .data-grid tbody tr .responsive-actions {
        border-bottom: 2px solid #ccc;
        margin-bottom: 12px;
    }

    .data-grid tbody tr td:first-child {
        border-top: none;
    }

    .data-grid .responsive-header {
        display: block;
    }

    .semAcoes{
        border-bottom: 2px solid #ccc !important;
        margin-bottom: 20px;
    }
    
}

.ordenaveis{
    width:100px;
    display:block;
    height:20px;
    background:black;
    color:white;
    text-decoration:none;
    padding-left:20px;
}
.ordenaveis:before{
    background:url('https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSj3XfIpf-OOJRCcWz4iN2CU3qMKVVbj0p0bRvKNGVo1U9pk_8ZIlyR8pWreA');
    background-size:cover;
    position:absolute;
    width:20px;
    height:20px;
    margin-left:-20px;
}

.ajusta-acoes{
    margin-left:53px;
}

/*
.item-selecao{
    height: 40px !important;
    font-size: 14px;
}

*/

