.cl-switch input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.cl-switch .switcher {
  display: inline-block;
  border-radius: 100px;
  width: 35px;
  height: 15px;
  background-color: #ccc;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
}

.cl-switch .switcher:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  margin-top: -2.5px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.cl-switch .switcher:active:before {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6), 0 0 0 10px rgba(63, 81, 181, 0.3);
  transition: all, 0.1s;
}

.cl-switch .label {
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 5px;
}

.cl-switch input[type="checkbox"]:checked + .switcher {
  background-color: rgba(2, 0, 41, 0.452);
}

.cl-switch input[type="checkbox"]:checked + .switcher:before {
  left: 100%;
  margin-left: -20px;
  background-color: rgb(2, 0, 41);
}

.cl-switch [disabled]:not([disabled="false"]) + .switcher {
  background: #ccc !important;
}

.cl-switch [disabled]:not([disabled="false"]) + .switcher:active:before {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
}

.cl-switch [disabled]:not([disabled="false"]) + .switcher:before {
  background-color: #e2e2e2 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
}

.cl-switch.cl-switch-large .switcher {
  width: 52px;
  height: 22px;
}

.cl-switch.cl-switch-large .switcher:before {
  width: 30px;
  height: 30px;
  margin-top: -4px;
}

.cl-switch.cl-switch-large .label {
  font-size: 18px;
}

.cl-switch.cl-switch-large input[type="checkbox"]:checked + .switcher:before {
  margin-left: -30px;
}

.cl-switch.cl-switch-xlarge .switcher {
  width: 87px;
  height: 37px;
}

.cl-switch.cl-switch-xlarge .switcher:before {
  width: 50px;
  height: 50px;
  margin-top: -6px;
}

.cl-switch.cl-switch-xlarge .label {
  font-size: 24px;
}

.cl-switch.cl-switch-xlarge input[type="checkbox"]:checked + .switcher:before {
  margin-left: -50px;
}

.cl-switch.cl-switch-black input[type="checkbox"]:checked + .switcher {
  background-color: #676767;
}

.cl-switch.cl-switch-black input[type="checkbox"]:checked + .switcher:before {
  background-color: #343434;
}

.cl-switch.cl-switch-black .switcher:active:before {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6), 0 0 0 10px rgba(52, 52, 52, 0.3);
}

.cl-switch.cl-switch-red input[type="checkbox"]:checked + .switcher {
  background-color: #ffcece;
}

.cl-switch.cl-switch-red input[type="checkbox"]:checked + .switcher:before {
  background-color: #ff6868;
}

.cl-switch.cl-switch-red .switcher:active:before {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6), 0 0 0 10px rgba(255, 104, 104, 0.3);
}

.cl-switch.cl-switch-green input[type="checkbox"]:checked + .switcher {
  background-color: #77e4c0;
}

.cl-switch.cl-switch-green input[type="checkbox"]:checked + .switcher:before {
  background-color: #29cc97;
}

.cl-switch.cl-switch-green .switcher:active:before {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6), 0 0 0 10px rgba(41, 204, 151, 0.3);
}

.cl-switch.cl-switch-orange input[type="checkbox"]:checked + .switcher {
  background-color: #ffc166;
}

.cl-switch.cl-switch-orange input[type="checkbox"]:checked + .switcher:before {
  background-color: #ff9800;
}

.cl-switch.cl-switch-orange .switcher:active:before {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6), 0 0 0 10px rgba(255, 152, 0, 0.3);
}

/* ---------------------------------------------
------------------[IOS style]----------------------------
 --------------------------------------------- */

.cl-switch.ios .switcher {
  width: 45px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #d3d3d5;
  box-shadow: 0 0 0 0 #4bd964 inset;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.cl-switch.ios .switcher:before {
  width: 24px;
  height: 24px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  top: -1px;
  left: -1px;
  margin-left: 0;
  border: 1px solid #d3d3d5;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.cl-switch.ios input[type="checkbox"]:checked + .switcher {
  background-color: #fff;
  box-shadow: 0 0 0 20px #4bd964 inset;
}

.cl-switch.ios input[type="checkbox"]:checked + .switcher:before {
  left: 100%;
  margin-left: -22px;
  background-color: #fff;
}

.cl-switch.ios [disabled]:not([disabled="false"]) + .switcher {
  background: #ccc !important;
}

.cl-switch.ios [disabled]:not([disabled="false"]) + .switcher:active:before {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
}

.cl-switch.ios [disabled]:not([disabled="false"]) + .switcher:before {
  background-color: #e2e2e2 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
}

.cl-switch.ios.cl-switch-large .switcher {
  width: 62px;
  height: 34px;
}

.cl-switch.ios.cl-switch-large .switcher:before {
  width: 34px;
  height: 34px;
}

.cl-switch.ios.cl-switch-large .label {
  font-size: 18px;
}

.cl-switch.ios.cl-switch-large input[type="checkbox"]:checked + .switcher:before {
  margin-left: -30px;
}

.cl-switch.ios.cl-switch-xlarge .switcher {
  width: 80px;
  height: 40px;
}

.cl-switch.ios.cl-switch-xlarge .switcher:before {
  width: 40px;
  height: 40px;
  margin-top: 0;
}

.cl-switch.ios.cl-switch-xlarge .label {
  font-size: 24px;
}

.cl-switch.ios.cl-switch-xlarge input[type="checkbox"]:checked + .switcher:before {
  margin-left: -39px;
}

.cl-switch.ios.cl-switch-black .switcher {
  box-shadow: 0 0 0 0 #343434 inset;
}

.cl-switch.ios.cl-switch-black input[type="checkbox"]:checked + .switcher {
  box-shadow: 0 0 0 20px #343434 inset;
  background: #ffffff;
}

.cl-switch.ios.cl-switch-black input[type="checkbox"]:checked + .switcher:before {
  background-color: #fff;
}

.cl-switch.ios.cl-switch-black .switcher:active:before {
  box-shadow: none;
}

.cl-switch.ios.cl-switch-red .switcher {
  box-shadow: 0 0 0 0 #ff6868 inset;
}

.cl-switch.ios.cl-switch-red input[type="checkbox"]:checked + .switcher {
  box-shadow: 0 0 0 20px #ff6868 inset;
  background: #ffffff;
}

.cl-switch.ios.cl-switch-red input[type="checkbox"]:checked + .switcher:before {
  background-color: #fff;
}

.cl-switch.ios.cl-switch-red .switcher:active:before {
  box-shadow: none;
}

.cl-switch.ios.cl-switch-green .switcher {
  box-shadow: 0 0 0 0 #29cc97 inset;
}

.cl-switch.ios.cl-switch-green input[type="checkbox"]:checked + .switcher {
  box-shadow: 0 0 0 20px #29cc97 inset;
  background: #ffffff;
}

.cl-switch.ios.cl-switch-green input[type="checkbox"]:checked + .switcher:before {
  background-color: #fff;
}

.cl-switch.ios.cl-switch-green .switcher:active:before {
  box-shadow: none;
}

.cl-switch.ios.cl-switch-orange .switcher {
  box-shadow: 0 0 0 0 #ff9800 inset;
}

.cl-switch.ios.cl-switch-orange input[type="checkbox"]:checked + .switcher {
  box-shadow: 0 0 0 20px #ff9800 inset;
  background: #ffffff;
}

.cl-switch.ios.cl-switch-orange input[type="checkbox"]:checked + .switcher:before {
  background-color: #fff;
}

.cl-switch.ios.cl-switch-orange .switcher:active:before {
  box-shadow: none;
}